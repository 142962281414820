import { serialize } from '@/@core/libs/serialize/util'
import Http from './http'

export const roleCreate = (params = '') => Http.post('/authorization/role', params)
export const rolesList = (params = '') => Http.get(`/authorization/role${serialize(params)}`)
export const roleByID = (params = '') => Http.get(`/authorization/role/${params}`)
export const roleUpdate = (params = '') => Http.put(`/authorization/role/${params.id}`, params)
export const roleDestroy = (params = '') => Http.delete(`/authorization/role/${params.id}`)

export const permissionCreate = (params = '') => Http.post('/authorization/permission', params)
export const permissionsList = (params = '') => Http.get(`/authorization/permission${serialize(params)}`)
export const permissionByID = (params = '') => Http.get(`/authorization/permission/${params}`)
export const permissionUpdate = (params = '') => Http.put(`/authorization/permission/${params.id}`, params)
export const permissionDestroy = (params = '') => Http.delete(`/authorization/permission/${params.id}`)

// // FAQSCATEGORIES
export const faqcategoryCreate = (params = '') => Http.post('/faq-categories', params)
export const faqscategoriesList = (params = '') => Http.get(`/faq-categories${serialize(params)}`)
export const faqcategoryByID = (params = '') => Http.get(`/faq-categories/${params}`)
export const faqcategoryUpdate = (params = '') => Http.put(`/faq-categories/${params.id}`, params)
export const faqcategoryDestroy = (params = '') => Http.delete(`/faq-categories/${params.id}`)

// // // LIST ALL BENEFITS
export const benefitsList = (params = '') => Http.get(`/network-plan-benefits${serialize(params)}`)

export const benefitCreate = (params = '') => Http.post('/network-plan-benefits', params)
export const benefitByID = (params = '') => Http.get(`/network-plan-benefits/${params}`)
export const benefitUpdate = (params = '') => Http.put(`/network-plan-benefits/${params.id}`, params)
export const benefitDestroy = (params = '') => Http.delete(`/network-plan-benefits/${params.id}`)

// // // LIST DOMAINS
export const domainCreate = (params = '') => Http.post('/network-domains', params)
export const domainsList = (params = '') => Http.get(`/network-domains${serialize(params)}`)
export const domainByID = (params = '') => Http.get(`/network-domains/${params}`)
export const domainUpdate = (params = '') => Http.put(`/network-domains/${params.id}`, params)
export const domainDestroy = (params = '') => Http.delete(`/network-domains/${params.id}`)

// // // LIST ALL Documents Types
export const typeCreate = (params = '') => Http.post('/document-types', params)
export const typesList = (params = '') => Http.get(`/document-types${serialize(params)}`)
export const typeByID = (params = '') => Http.get(`/document-types/${params}`)
export const typeUpdate = (params = '') => Http.put(`/document-types/${params.id}`, params)
export const typeDestroy = (params = '') => Http.delete(`/document-types/${params.id}`)

// // // LIST ALL PLANS
export const planList = (params = '') => Http.get(`/network-plans${serialize(params)}`)
export const planByID = (params = '') => Http.get(`/network-plans/${params}`)
export const planCreate = (params = '') => Http.post('/network-plans', params)
export const planUpdate = (params = '') => Http.put(`/network-plans/${params.id}`, params)
export const planDestroy = (params = '') => Http.delete(`/network-plans/${params.id}`)

// // // LIST ALL Network Platforms
export const networkPlatformCreate = (params = '') => Http.post('/network-systems', params)
export const networkPlatformsList = (params = '') => Http.get(`/network-systems${serialize(params)}`)
export const networkPlatformByID = (params = '') => Http.get(`/network-systems/${params}`)
export const networkPlatformUpdate = (params = '') => Http.put(`/network-systems/${params.id}`, params)
export const networkPlatformDestroy = (params = '') => Http.delete(`/network-systems/${params.id}`)

// // // LIST ALL Documents Websites Types
export const websitetypeCreate = (params = '') => Http.post('/document-website-types', params)
export const websitetypesList = (params = '') => Http.get(`/document-website-types${serialize(params)}`)
export const websitetypeByID = (params = '') => Http.get(`/document-website-types/${params}`)
export const websitetypeUpdate = (params = '') => Http.put(`/document-website-types/${params.id}`, params)
export const websitetypeDestroy = (params = '') => Http.delete(`/document-website-types/${params.id}`)

// // // LIST ALL ROADMAP STATUS
export const statusCreate = (params = '') => Http.post('/roadmaps-status', params)
export const statusList = (params = '') => Http.get(`/roadmaps-status${serialize(params)}`)
export const statusByID = (params = '') => Http.get(`/roadmaps-status/${params}`)
export const statusUpdate = (params = '') => Http.put(`/roadmaps-status/${params.id}`, params)
export const statusDestroy = (params = '') => Http.delete(`/roadmaps-status/${params.id}`)

// // // LIST ALL Documents Websites
export const websiteCreate = (params = '') => Http.post('/document-website', params)
export const websitesList = (params = '') => Http.get(`/document-website${serialize(params)}`)
export const websiteByID = (params = '') => Http.get(`/document-website/${params}`)
export const websiteUpdate = (params = '') => Http.put(`/document-website/${params.id}`, params)
export const websiteDestroy = (params = '') => Http.delete(`/document-website/${params.id}`)

// // // LIST ALL Day Tips
export const tipCreate = (params = '') => Http.post('/day-tips', params)
export const tipsList = (params = '') => Http.get(`/day-tips${serialize(params)}`)
export const tipByID = (params = '') => Http.get(`/day-tips/${params}`)
export const tipUpdate = (params = '') => Http.put(`/day-tips/${params.id}`, params)
export const tipDestroy = (params = '') => Http.delete(`/day-tips/${params.id}`)

// // // LIST ALL PRODUCTS
export const productList = (params = '') => Http.get(`/network-system-products${serialize(params)}`)
export const productByID = (params = '') => Http.get(`/network-system-products/${params}`)
export const productCreate = (params = '') => Http.post('/network-system-products', params)
export const productUpdate = (params = '') => Http.put(`/network-system-products/${params.id}`, params)
export const productDestroy = (params = '') => Http.delete(`/network-system-products/${params.id}`)

// // // LIST ALL PRODUCTS BUNDLES
export const productBundleList = (params = '') => Http.get(`/product-bundles${serialize(params)}`)
export const productBundleByID = (params = '') => Http.get(`/product-bundles/${params}`)
export const productBundleCreate = (params = '') => Http.post('/product-bundles', params)
export const productBundleUpdate = (params = '') => Http.put(`/product-bundles/${params.bundle_id}`, params)
export const productBundleDestroy = (params = '') => Http.delete(`/product-bundles/${params.bundle_id}`)
export const productBundleListInside = (params = '') => Http.get(`/product-bundles/${params.uuid}/prices`)

// LIST ALL Workflow Status
export const workflowList = (params = '') => Http.get(`/status-workflow-stages${serialize(params)}`)
export const workflowCreate = (params = '') => Http.post('/status-workflow-stages', params)
export const workflowByID = (params = '') => Http.get(`/status-workflow-stages/${params}`)
export const workflowUpdate = (params = '') => Http.put(`/status-workflow-stages/${params.id}`, params)
export const workflowDestroy = (params = '') => Http.delete(`/status-workflow-stages/${params.id}`)
