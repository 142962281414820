const networkSystem = [
	{
		path: '/network/network-systems/acl/roles/list',
		name: 'network-acl-roles-list',
		component: () => import('@/views/network/network-systems/acl/roles/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/acl/permissions/list',
		name: 'network-acl-permissions-list',
		component: () => import('@/views/network/network-systems/acl/permissions/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/operational-area/list',
		name: 'network-operational-area-list',
		component: () => import('@/views/network/network-systems/operational-area/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/members/list',
		name: 'network-members-list',
		component: () => import('@/views/network/network-systems/members/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/members/new',
		name: 'network-members-create',
		component: () => import('@/views/network/network-systems/members/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/members/edit/:id',
		name: 'network-members-edit',
		component: () => import('@/views/network/network-systems/members/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/network-platforms/list',
		name: 'network-network-platforms-list',
		component: () => import('@/views/network/network-systems/network-platforms/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/documents-websites-version/:id',
		name: 'websites-list-versions',
		component: () => import('@/views/network/network-systems/documents-websites-version/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/documents-websites/list',
		name: 'network-documents-websites-list',
		component: () => import('@/views/network/network-systems/documents-websites/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/documents-types/list',
		name: 'network-documents-types-list',
		component: () => import('@/views/network/network-systems/documents-types/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/domains/list',
		name: 'network-domains-list',
		component: () => import('@/views/network/network-systems/domains/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/roadmap/list',
		name: 'network-roadmap-list',
		component: () => import('@/views/network/network-systems/roadmap/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/roadmap/create',
		name: 'network-roadmap-create',
		component: () =>
			import(/* webpackChunkName: "NetworkRoadmapCreate" */ '@/views/network/network-systems/roadmap/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/roadmap/edit/:id',
		name: 'network-roadmap-edit',
		component: () =>
			import(/* webpackChunkName: "NetworkRoadmapEdit" */ '@/views/network/network-systems/roadmap/new.vue'),
		meta: {
			layout: 'content',
		},
	},

	{
		path: '/network/network-systems/roadmap-status/list',
		name: 'network-roadmap-status-list',
		component: () => import('@/views/network/network-systems/roadmap-status/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/benefits/list',
		name: 'network-benefits-list',
		component: () => import('@/views/network/network-systems/benefits/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/plans/list',
		name: 'network-plans-list',
		component: () => import('@/views/network/network-systems/plans/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/plans/create',
		name: 'network-plans-create',
		component: () => import('@/views/network/network-systems/plans/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/plans/edit/:id',
		name: 'network-plans-edit',
		component: () =>
			import(/* webpackChunkName: "NetworkPlansEdit" */ '@/views/network/network-systems/plans/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/faqs/list',
		name: 'network-faqs-list',
		component: () => import('@/views/network/network-systems/faqs/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/faqs/faqs-categories/list',
		name: 'network-faqs-categories-list',
		component: () => import('@/views/network/network-systems/faqs/faqs-categories/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/day-tips/list',
		name: 'network-day-tips-list',
		component: () => import('@/views/network/network-systems/day-tips/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/documents/list',
		name: 'dashboard-analytics',
		// component: () => import('@/views/network/network-systems/products/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/products/list',
		name: 'network-products-list',
		component: () => import('@/views/network/network-systems/products/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/products/create',
		name: 'network-products-create',
		component: () => import('@/views/network/network-systems/products/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/products/edit/:id',
		name: 'network-products-edit',
		component: () =>
			import(/* webpackChunkName: "NetworkProductsEdit" */ '@/views/network/network-systems/products/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/product-bundles/list',
		name: 'network-product-bundles-list',
		component: () => import('@/views/network/network-systems/product-bundles/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/product-bundles/create',
		name: 'network-product-bundles-create',
		component: () => import('@/views/network/network-systems/product-bundles/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/product-bundles/edit/:bundleId',
		name: 'network-product-bundles-edit',
		component: () =>
			import(
				/* webpackChunkName: "NetworkProductBundlesEdit" */ '@/views/network/network-systems/product-bundles/new.vue'
			),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/network-systems/workflow-status/list',
		name: 'network-workflow-status-list',
		component: () => import('@/views/network/network-systems/workflow-status/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default networkSystem
