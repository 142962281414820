export default {
	continue: 'Continue',
	add: 'Add',
	addCategoryPeriodicity: 'Add category and periodicity',
	categoryPeriodicity: 'Category and periodicity',
	healthCheck: 'Health Check',
	healthCheckSubTitle: 'Check out the status of Mundo Gamer Network Platforms',
	networkFilterInfo:
		'* For this campaign to be used on 1 platform only, please select a platform. To use it on all platforms, leave it unselected.',
	teamBirthdays: 'Upcoming Birthdays',
	teamBirthdaysSubTitle: 'Check out the next Birthdays of Mundo Gamer Team',
	teamYears: 'years old',
	Schedulers: 'Schedulers',
	image: 'Image',
	maxEmployees: 'Maximum employees',
	maxUses: 'Maximum uses',
	maxUsesUser: 'Maximum uses per user',
	maxUsesItem: 'Maximum uses per item',
	directUser: 'Directed to user',
	minEmployees: 'Minimum employees',
	quantityEmployees: 'Number of employees',
	employees: 'employees',
	edition: 'Edition',
	cover: 'Cover',
	shortDescription: 'Short description',
	professionalDescription: 'Professional Description',
	dragDropImage: 'Drag Drop an Image',
	back: 'Back',
	all: 'All',
	yes: 'Yes',
	no: 'No',
	version: 'Version',
	versions: 'Versions',
	parameter: 'Parameter',
	favorite: 'Favorite',
	competitors: 'Competitors',
	competitor: 'Competitor',
	new: 'New',
	post: 'Post',
	fullAddress: 'Full address',
	address: 'Address',
	postalCode: 'Postal Code',
	localization: 'Localization',
	street: 'Street',
	number: 'Number',
	neighborhood: 'Neighborhood',
	complement: 'Complement',
	nationality: 'Nationality',
	zipCode: 'Zip Code',
	city: 'City',
	cities: 'Cities',
	state: 'State',
	states: 'States',
	country: 'Country',
	countries: 'Countries',
	flag: 'Flag',
	officialName: 'Official Name',
	shortName: 'Short Name',
	callingCode: 'Calling Code',
	countryOperation: 'Country Operation',
	region: 'Region',
	regionInterest: 'Region Interest',
	administratorName: 'Administrator Name',
	admissionDate: 'Admission Date',
	demissionDate: 'Demission Date',
	managementExperience: 'Management Experience',
	intention: 'Intention',
	phone: 'Telephone',
	mobilePhone: 'Mobile Phone',
	membership: 'Membership',
	file: 'File',
	planName: 'Plan Name',
	alias: 'Alias',
	documents: 'Documents',
	documentsInformation: 'Documents Informations',
	documentName: 'Document Name',
	memberPosition: 'Member Position',
	meetingURL: 'Meeting Url',
	goal: 'Goal',
	purpose: 'Purpose',
	logout: 'Logout',
	profile: 'Profile',
	profileProgress: 'Profile Progress',
	applicationStatuss: 'Application Status',
	applicationMethod: 'Application Method',
	method: 'Method',
	application: 'Application',
	applicationDate: 'Application date',
	categories: 'Categories',
	skills: 'Skills',
	skill: 'Skill',
	revised: 'Revised',
	subCategories: 'Subcategories',
	category: 'Category',
	categoryName: 'Category Name',
	subCategory: 'SubCategory',
	title: 'Title',
	subtitle: 'Subtitle',
	source: 'Source',
	share: 'Share',
	author: 'Author',
	creatorBy: 'Created by',
	publicationDate: 'Publication Date',
	public: 'Public',
	name: 'Name',
	alternativeName: 'Alternative Name',
	termsUse: 'Terms of Use',
	termsAccepted: 'Terms accepted at:',
	privacyPolicy: 'Privacy Policy',
	language: 'Language',
	close: 'Close',
	save: 'Save',
	update: 'Update',
	settings: 'Settings',
	active: 'Active',
	inactive: 'Inactive',
	blocked: 'Blocked',
	deleted: 'Deleted',
	description: 'Description',
	confirmation: 'Confirmation',
	confirmDelete: 'Are you sure you want to remove the item {item} ?',
	confirmDeleteApplication: 'Are you sure you want to remove: {item} ?',
	startTypeSearch: 'Start typing to Search',
	search: 'Search',
	contact: 'Contact',
	experience: 'Experience',
	languageLevels: 'Language Levels',
	employmentType: 'Employment Type',
	per: 'Payment Periods',
	workMethodology: 'Work Methodology',
	Methodology: 'Methodology',
	workAvailability: 'Work Availability',
	availability: 'Availability',
	workHour: 'Work Hour',
	numberVacancies: 'Number Vacancies',
	vacancies: 'Vacancies',
	vacancy: 'Vacancy',
	specialVacancy: 'Special Vacancy',
	notSpecial: 'Not Special',
	pwd: 'PwD',
	numberPlatforms: 'Number of Platforms',
	currency: 'Currency',
	symbol: 'Symbol',
	amount: 'Amount',
	price: 'Price',
	pricePublic: 'Price Public',
	priceCut: 'Price reduction',
	originalPrice: 'Original Price',
	localizedPrices: 'Localized prices',
	minimumSalary: 'Minimum Salary',
	maximumSalary: 'Maximum Salary',
	institution: 'Institution',
	educationLevels: 'Education Levels',
	courseName: 'Course Name',
	initialDate: 'Initial date',
	finalDate: 'Final date',
	position: 'Position',
	currentJob: 'Current Job',
	companyPerks: 'Company Perks',
	vacancyPaymentInformations: 'Formas de Contratação e Pagamento',
	icon: 'Icon',
	status: 'Status',
	publicStatus: 'Public Status',
	systemStatus: 'System Status',
	positiveRate: 'Positive Rate',
	negativeRate: 'Negative Rate',
	introduction: 'Introduction ',
	conclusion: 'Conclusion',
	externalLink: 'External Link',
	primarilyQuestion: 'What is primarily taught in your course?',
	type: 'Type',
	text: 'Text',
	NotificationTitle: 'Notification Title (max 76 characters)',
	NotificationMessage: 'Notification Message (max 135 characters)',
	sendNotification: 'Send Notification',
	nameAbrev: 'Abbreviation Name',
	statusName: 'Status Name',
	level: 'Level',
	model: 'Model',
	timeQuiz: 'Time for answering one question (seconds)',
	typeQuiz: 'Quiz Type',
	themeQuiz: 'Quiz Theme',
	nameType: 'Type Name',
	question: 'Question',
	countQuestions: 'Questions',
	totalQuestions: 'Total Questions',
	totalVotes: 'Total Votes',
	totalCompetitors: 'Total Competitors',
	countAnswers: 'Alternatives',
	answer: 'Answer',
	correctAnswer: 'Correct Answer',
	answerOptions: 'Answer Options',
	participants: 'Participants',
	required: 'Field required',
	financialInformation: 'Financial Informations',
	personalInformation: 'Personal Informations',
	professionalInformation: 'Professional Informations',
	accountInformations: 'Account Informations',
	languagePreferred: 'Language Preferred',
	verified: 'Verified',
	bankAccount: 'Bank Account',
	bankName: 'Bank Name',
	bankAgency: 'Agency',
	request: 'Request',
	requestDate: 'Request Date',
	operation: 'Operation',
	mainAccount: 'Main Account',
	main: 'Main',
	gameName: 'Game Name',
	gameType: 'Game Type',
	gameStatus: 'Game Status',
	gameMode: 'Game Mode',
	gameModes: 'Game Modes',
	gameEngine: 'Game Engine',
	gameEngines: 'Game Engines',
	genreName: 'Genre Name',
	playerPerspective: 'Player Perspective',
	themes: 'Themes',
	themesVariables: 'Themes Variables',
	similarGames: 'Similar Games',
	characters: 'Characters',
	character: 'Character',
	phrase: 'Phrase',
	gender: 'Gender',
	specie: 'Specie',
	games: 'Games',
	game: 'Game',
	platformType: 'Platform Type',
	platformFamily: 'Platform Family',
	predecessor: 'Predecessor',
	successor: 'Successor',
	generation: 'Generation',
	releaseDate: 'Release Date',
	lastUpdate: 'Last Update',
	actions: 'Actions',
	in: 'in',
	total: 'Total',
	published: 'Published',
	pending: 'Pending',
	disapproved: 'Disapproved',
	approved: 'Approved',
	age: 'Age',
	remove: 'Remove',
	pendingReview: 'Pending Review',
	scheduled: 'Scheduled',
	document: 'Document',
	code: 'Code',
	sizeError: 'The image size must be ',
	workingHour: 'Working Hour',
	priority: 'Priority',
	viewMessage: 'View Message',
	invoice: 'Invoice',
	invoiceDate: 'Invoice Date',
	dueDate: 'Due Date',
	billTo: 'Bill To',
	items: 'Items',
	itemName: 'Item Name',
	billSingle: 'Bill single customer',
	billMultiple: 'Bill multiple customers',
	tax: 'Tax',
	quantity: 'Quantity',
	salesperson: 'Salesperson',
	couponCode: 'Coupon code',
	couponInfoPart1:
		'*The coupon code is the name the user must use for the discount to be applied. for a coupon to be valid for all items in the cart, do not select any specific product, package, or plan.',
	couponInfoPart2:
		'*For specific and limited use of items, specify for which plan, package, country, or currency the coupon code will be valid.',
	couponInfoPart3:
		'*It is possible to create a global coupon or specify validity for a specific country or currency.',
	discount: 'Discount %',
	discountBundlePriceInfo:
		'* To maintain the original value set in the Prices tab, leave the discount field empty so that the discount is automatically calculated, maintaining the original value.',
	discountBundlePriceInfo2:
		'If you want the system to automatically calculate the values ​​of the Packages, enter the % discount you want.',
	note: 'Note',
	noteOptional: 'Note (optional)',
	sale: 'Sale',
	invoiceNumber: 'Invoice Number',
	sendInvoice: 'Send Invoice',
	professionalSituation: 'Professional Situation',
	reportedItem: 'Reported Item',
	reportedOn: 'Reported On',
	resolvedOn: 'Resolved On',
	reportedIdentifier: 'Reported Identifier',
	reportedResource: 'Reported Resource',
	motive: 'Motive',
	reportedBy: 'Reported By',
	general: 'General',
	loadMore: 'Load more',
	responsible: 'Responsible',

	noLanguages: 'No Languages',
	noCertifications: 'No Certifications',
	noGraduations: 'No Graduations',
	noSkills: 'No Skills',
	noExperiences: 'No Experiences',
	certifications: 'Certifications',
	graduations: 'Graduations',
	experiences: 'Experiences',
	viewResume: 'Ver currículo',

	viewers: 'Viewers',
	channel: 'Channel',
	channelName: 'Channel Name',
	affiliate: 'Affiliate',
	provider: 'Provider',
	registeredOn: 'Registered on',
	expiresOn: 'Expires on',
	domain: 'Domain',
	status: 'Status',

	visibility: 'Visibility',
	show: 'Show',
	hide: 'Hide',
	not: 'Not',
	read: 'Read',
	notread: 'Not Read',
	value: 'Value',
	estimatedValue: 'Estimated Value',
	valueCredits: 'Value in Credits',
	originalValue: 'Original Value',
	subject: 'Subject',

	sumContents: 'Sum Contents',
	contentAnswer: 'Content Answer',

	cancel: 'Cancel',
	success: 'Success',
	savedSuccess: 'Saved with Success',
	updatedSuccess: 'Updated with Success',
	deletedSuccess: 'Deleted with Success',

	updatedAt: 'Updated at',
	clientSecret: 'Client Secret',
	career: 'Career',
	careers: 'Careers',

	sentAt: 'Sent at',
	counts: 'Counts',
	results: 'Results',

	employmentStatus: 'Employment Status',
	visaSponsorship: 'Visa Sponsorship',

	operationalArea: 'Operational Area',
	projectName: 'Project Name',
	mgPlatform: 'MG Platform',
	key: 'Key',
	keysCount: 'Total Keys',
	statesCount: 'States Count',

	date: 'Date',
	dateBirth: 'Date of Birth',

	birthday: 'Birthday',
	birthdayToday: 'Is today?',
	hour: 'Hour',
	currentGeneration: 'Current Generation',
	currentlyUsed: 'Currently Used',
	days: 'days',
	closing: 'Closing in',
	closed: 'Closed',
	preview: 'Preview',

	totalSize: 'Total Size',
	totalArea: 'Total Area',
	franchiseModel: 'Franchise Model',

	scheduledDate: 'Scheduled Date',

	views: 'Views',
	viewAll: 'View All',
	view: 'View',
	edit: 'Edit',
	download: 'Download',
	delete: 'Delete',
	clicks: 'Clicks',
	start: 'Start',
	finish: 'Finish',
	location: 'Location',
	guidelines: 'Guidelines',
	developed: 'Developed',
	manufactured: 'Manufactured',
	images: 'Images',
	imageCover: 'Image Cover',
	currentImageCover: 'Current Image Cover',
	imageBanner: 'Image Banner',
	currentImageBanner: 'Current Image Banner',
	imageIcon: 'Image Icon',
	galleryImage: 'Gallery Image',

	numberCompanies: 'Number of Companies',
	partner: 'Partner',
	partners: 'Partners',
	domains: 'Domains',
	teams: 'Teams',
	users: 'Users',
	color: 'Color',

	captain: 'Captain',
	members: 'Members',
	member: 'Member',
	events: 'Events',
	event: 'Event',
	ongoingEvents: 'Ongoing Events',
	upcomingEvents: 'Upcoming Events',
	pastEvents: 'Past Events',

	fantasyName: 'Fantasy Name',

	company: 'Company',
	companyInformation: 'Company Informations',
	companyName: 'Company Name',
	studioName: 'Studio Name',
	companyType: 'Company Type',
	companyHour: 'Company Working Hour',
	companySize: 'Company Size',
	createdDate: 'Created Date',
	startYear: 'Start Year',
	lastYear: 'Last Year',
	updatedDate: 'Update Date',
	publishDate: 'Publish Date',
	expirationDate: 'Expiration Date',
	foundationDate: 'Foundation Date',
	startDate: 'Start Date',
	closureDate: 'Closure Date',
	startTime: 'Start Time',
	closureTime: 'Closure Time',

	registrationNumber: 'Registration number',
	inscriptionNumber: 'Inscription number',
	openingDate: 'Opening Date',
	descriptionOfActivities: 'Description of activities',

	firstName: 'First Name',
	lastName: 'Last Name',
	nickName: 'Nickname',
	location: 'Location',
	origin: 'Origin',
	platform: 'Platform',
	collection: 'Collection',
	franchise: 'Franchise',
	genre: 'Genre',
	release: 'Release',
	platforms: 'Platforms',
	createdAt: 'Created At',
	viewMore: 'View More',
	role: 'Role',
	rolesPermissions: 'Roles & Permissions',
	roles: 'Roles',
	latestReviewsCommentary: 'Latest Ratings Commentary',
	contentsComments: 'Contents Comments',
	upcomingGames: 'Upcoming Games',
	latestGames: 'Latest Games',
	gamesPublished: 'Games Published',
	gamesDeveloped: 'Games Developed',
	achievement: 'Achievement',
	trophiesType: 'Trophies Type',
	trophie: 'Trophie',
	owners: 'Owners',
	popularity: 'Popularity',
	minimumAge: 'Minimum Age',
	transactions: 'Transactions',
	contentType: 'Content Type',
	contentTypeId: 'Content Id',
	validyDays: 'Valid days',

	operatingSystem: 'Operating System',
	memory: 'Memory',
	graphics: 'Graphics',
	supportedResolutions: 'Supported Resolutions',
	media: 'Media',
	onlineServices: 'Online Services',
	storage: 'Storage',
	sound: 'Sound',
	output: 'Output',
	connectivity: 'Connectivity',

	followers: 'Followers',
	news: 'News',

	details: 'Details',
	detail: 'Detail',

	loadingText: 'Loading... Wait.',
	translations: 'Translations',
	noTranslation: 'No translation',

	topicName: 'Topic Name',
	completed: 'Completed',
	editStage: 'Edit Stage',
	newStage: 'New Stage',

	deleteNotification: 'Are you sure you want to delete this item',
	deleteNotificationAccount: 'Are you sure you want to delete this account?',

	titleHighlight: 'Title Highlights',
	buttonText: 'Button Text',
	buttonColor: 'Button Color',
	backgroundColor: 'Background Color',
	titleColor: 'Title Color',
	styleSettings: 'Style Settings',
	highlight: 'Highlight',
	highlightBanner: 'Highlight Banner',
	tvBanner: 'TV Banner',
	worldwide: 'Worldwide',

	credentials: 'Credentials',
	account: 'Account',
	client: 'Client',

	checkVideo: 'Check Video',
	videoIsValid: 'URL is valid',
	videoIsNotValid: 'URL is invalid',
	getThumbnail: 'Get Video Thumbnail',

	magazineURL: 'Magazine URL',
	heyzineCreate: 'Create Heyzine magazine',

	translate: {
		translate: 'Translate',
		confirm: 'Would you like to translate?',
		language: 'Choose target languages for translation',
		notification: 'Translation added to the queue',
	},

	menu: {
		blog: 'Blog Contents',
		institutional: 'Institutional',
		messages: 'Messages',
		profiles: 'Profiles',
		profilesCategories: 'Profiles Categories',
		languageModel: 'Language Model',
		services: 'Services',
		tokenConfigs: 'Token Configs',
		settings: 'Settings',
		socialiteProviders: 'Socialite Providers',
		awards: 'Awards',
		awardsCategories: 'Awards Categories',
		magazines: 'Magazines',
		institutions: 'Educational institutions',
		projectsAndKeys: {
			title: 'Projects & Keys',
			list: 'Keys List',
			projects: 'List of Projects',
		},
		careers: {
			title: 'Careers',
			applications: 'Applications',
			careers: 'Careers',
			skills: 'Skills',
		},
		partners: {
			title: 'Partners',
			list: 'Partners List',
			categories: 'Partners Categories',
		},
		financialSystem: {
			title: 'Financial System',
			currencies: 'Currencies',
			invoices: 'Invoices',
			expensesCategories: 'Expenses Categories',
			paymentMethods: 'Payment Methods',
			paymentPeriods: 'Payment Periods',
			paymentFees: 'Payment Fees',
			paymentRefunds: 'Payment Refunds',
			paymentStatus: 'Payment Status',
			paymentTaxes: 'Payment Taxes',
			paymentTypes: 'Payment Types',
			banks: 'Banks',
			cupons: 'Discount coupons',
		},
		languagesAndLocations: {
			title: 'Languages & Locations',
			cities: 'Cities',
			countries: 'Countries',
			currencies: 'Currencies',
			languages: 'Languages',
			locations: 'Locations',
			nationalities: 'Nationalities',
			regions: 'Regions',
			states: 'States',
			timezones: 'Timezones',
		},
		pressMedia: {
			title: 'Press & Media',
			posts: 'Press Posts',
			files: 'Media Files',
		},
		networkSystems: {
			title: 'Network Systems',
			documents: 'Documents',
			documentsTypes: 'Documents Types',
			documentsWebsites: 'Documents Websites',
			domains: 'Domains',
			MGPlatforms: 'MG Platforms',
			operationalArea: 'Operational Area',
			roadmap: 'Roadmap',
			roadmapStatus: 'Roadmap Status',
			teamMembers: 'Team Members',
			FAQs: 'FAQs',
			FAQsCategories: 'FAQs Categories',
			ACL: {
				rolesList: 'Roles List',
				permissionsList: 'Permissions List',
			},
			workflow: 'Workflow Status',
			plans: 'Plans',
			benefits: 'Benefits',
			dayTips: 'Tips of the day',
			products: 'Products',
			productBundles: 'Product Bundles',
		},
		themes: {
			themes: 'Themes',
			appThemes: 'App Themes',
			projectsVariables: 'Projects Variables',
		},
		reports: {
			title: 'Reports',
			payments: 'Payments',
			operational: 'Operational Cost',
		},
		todo: {
			title: 'To Do',
			items: 'To Do Items',
			list: 'To Do Lists',
		},
		users: {
			title: 'Users',
			list: 'Users List',
			group: 'Users Group',
			genders: 'Users Genders',
		},
		polls: {
			title: 'Polls',
			list: 'Polls List',
			new: 'New Poll',
		},
		companies: {
			companies: 'Companies',
			companiesList: 'Companies List',
			companiesType: 'Companies Type',
			companiesSize: 'Companies Size',
			companiesStage: 'Companies Stage',
		},
		community: {
			communities: {
				communities: 'Communities',
				creators: 'Creators',
				features: 'Features',
				ratings: 'Ratings',
				comments: 'Comments',
			},
			pulses: {
				pulses: 'Pulses',
				category: 'Categories',
				pending: 'Pending',
				source: 'Sources',
				status: 'Status',
			},
			wikigamer: {
				achievements: 'Achievements',
				characters: {
					characters: 'Characters',
					genders: 'Genders',
					phrases: 'Phrases',
					species: 'Species',
					status: 'Status',
					statusWebsite: 'Website Status',
				},
				collections: 'Collections',

				events: {
					events: 'Events',
					eventsList: 'Events List',
					eventsCategories: 'Events Categories',
				},
				franchises: 'Franchises',
				games: {
					games: 'Games',
					gamesAchievements: 'Games Achievements',
					gamesTypes: 'Games Types',
					gamesEngines: 'Games Engines',
					gamesGenres: 'Games Genres',
					gamesModes: 'Games Modes',
					gamesPerspectives: 'Games Perspectives',
					gamesStatus: 'Games Status',
					gamesThemes: 'Games Themes',
					gamesWebsites: 'Games Websites',
				},
				gamesRating: {
					gamesRating: 'Games Rating',
					ESRBRatings: 'ESRB Ratings',
					ESRBContents: 'ESRB Contents',
					PegiRatings: 'Pegi Ratings',
					PegiContents: 'Pegi Contents',
				},
				mediaTypes: 'Media Types',
				regions: 'Regions',
				platforms: {
					platforms: 'Platforms',
					generations: 'Generations',
					platformsFamily: 'Platforms Family',
					platformsType: 'Platforms Type',
				},
			},
			Schedulers: 'Schedulers',
			pushNotifications: 'Push Notifications',
			quizzes: 'Quizzes',
			reviews: 'Reviews',
			videos: {
				videos: 'Videos',
				category: 'Categories',
			},
			translationQueue: 'Translation Queue',
		},
		agency: {
			services: 'Services',
			categories: 'Categories',
			contactMessages: 'Requests',
			audience: 'Target Audience',
			workflow: 'Workflow Status',
			game: 'Project Status',
			goals: 'Goals',
			projects: 'Projects',
			marketing: {
				highlights: 'Web Highlights',
				highlightBannersApp: ' App Banners',
				highlightBannersWeb: 'Web Banners',
				tvBanners: 'TV Banners',
				providers: 'Providers',
				campaigns: 'Campaigns',
			},
			newsletter: {
				categories: 'Categories',
				periodicities: 'Periodicities',
				campaigns: 'Campaigns',
			},
		},
		academy: {
			courses: 'Courses',
			categories: 'Categories',
			subcategories: 'SubCategories',
			formats: 'Formats',
			levels: 'Levels',
		},

		club: {
			franchiseModels: 'Franchise Models',
			contactFranchise: 'Contact Franchise',
		},
		exchange: {},
		jobs: {
			vacancies: 'Vacancies',
			vacanciesDrop: {
				list: 'List of Vacancies',
				status: 'List of Vacancies Status',
				applications: 'Applications',
			},
			resumes: 'Resumes',
			employment: {
				employment: 'Employment',
				employmentType: 'Employment Type',
			},
			experiences: 'Experiences',
			workHours: 'Work Hours',
			workMethodologies: 'Work Methodologies',
			workAvailabilities: 'Work Availabilities',
			industrySectors: 'Industry Sectors',
			professionalSituations: 'Professional Situations',
		},
		shop: {},
		token: {},
		tv: {
			banners: {
				rotatingBanners: 'Rotating Banners',
			},
			streamersApplications: 'Applications',
		},
		accountBenefits: 'Account Benefits',
		website: 'Website',
		pollList: 'Polls List',
		newPoll: 'New Poll',
	},

	messages: {
		list: {
			title: 'List of Messages',
			subTitle: 'Check the list of Messages',
		},
	},

	newsletters: {
		list: {
			title: 'List of Mundo Gamer Newsletters',
			subTitle: 'Check the list of Newsletters registered on the the Mundo Gamer.',
		},
		create: {
			title: 'New Newsletter',
			subTitle: 'Newsletter',
		},
		update: {
			title: 'Update Newsletter',
			subTitle: 'Newsletter',
		},
		delete: {
			title: 'The Newsletter was deleted successfully',
		},
	},

	faqs: {
		list: {
			title: 'List of FAQS',
			subTitle: 'Check the list of FAQs registered on the all platforms of the Mundo Gamer Network',
		},
		create: {
			title: 'New FAQ Question',
			subTitle: 'FAQ',
		},
		delete: {
			title: 'The FAQ was deleted successfully',
		},
	},

	themes: {
		list: {
			title: 'List of Themes',
			subTitle: 'Check the list of Themes registered on the platforms of the Mundo Gamer Network',
		},
		create: {
			title: 'New Theme',
			subTitle: 'Theme',
		},
	},
	projectsVariables: {
		list: {
			title: 'List of Projects Variables',
			subTitle: 'Check the list of Projects Variables registered on the platforms of the Mundo Gamer Network',
		},
		create: {
			title: 'New Project Variable',
			subTitle: 'Project Variable',
		},
	},

	companies: {
		list: {
			title: 'List of Mundo Gamer Companies',
			subTitle: 'Check the list of Latest Companies registered on the the Mundo Gamer.',
		},
		create: {
			title: 'New Company',
			subTitle: 'Fill in the fields below to register a new Company',
		},
		update: {
			title: 'Update Company',
		},
	},

	companiesType: {
		list: {
			title: 'List of Mundo Gamer Companies Type',
			subTitle: 'Check the list of Latest Companies Type registered on the the Mundo Gamer.',
		},
		create: {
			title: 'New Company Type',
			subTitle: 'Company Type',
		},
		delete: {
			title: 'The Companies Type was deleted successfully',
		},
	},

	companiesSize: {
		list: {
			title: 'List of Mundo Gamer Company Sizes',
			subTitle: 'Check the list of Latest Company Sizes registered on the the Mundo Gamer.',
		},
		create: {
			title: 'New Company Size',
			subTitle: 'Company Size',
		},
		delete: {
			title: 'The Company Size was deleted successfully',
		},
	},

	companiesStage: {
		list: {
			title: 'List of Mundo Gamer Company Stages',
			subTitle: 'Check the list of Latest Company Stages registered on the the Mundo Gamer.',
		},
		create: {
			title: 'New Company Stage',
			subTitle: 'Company Stage',
		},
		delete: {
			title: 'The Company Stage was deleted successfully',
		},
	},

	accountBenefits: {
		list: {
			title: 'List of Account Benefits',
			subTitle: 'Check the list of Account Benefits registered on the all platforms of the Mundo Gamer Network',
		},
		create: {
			title: 'New Account Benefit',
			subTitle: 'Account Benefit',
		},
		delete: {
			title: 'The Account Benefit was deleted successfully',
		},
	},
	institutions: {
		list: {
			title: 'List of Mundo Gamer Educational institutions',
			subTitle: 'Check the list of Latest Educational institutions registered on the the Mundo Gamer Academy.',
		},
		create: {
			title: 'New Educational institution',
			subTitle: 'Educational institution',
		},
		update: {
			title: 'Update Educational institution',
			subTitle: 'Educational institution',
		},
		delete: {
			title: 'The Educational institution was deleted successfully',
		},
	},
	academy: {
		courses: {
			courses: {
				list: {
					title: 'List of Mundo Gamer Academy Courses',
					subTitle: 'Check the list of Latest Courses registered on the the Mundo Gamer Academy.',
				},
				create: {
					title: 'New Course',
					subTitle: 'Course',
				},
				update: {
					title: 'Update Course',
					subTitle: 'Course',
				},
				delete: {
					title: 'The Course was deleted successfully',
				},
			},
			categories: {
				list: {
					title: 'List of Mundo Gamer Academy Courses Categories',
					subTitle: 'Check the list of Latest Courses Categories registered on the the Mundo Gamer Academy.',
				},
				create: {
					title: 'New Course Category',
					subTitle: 'Category',
				},
			},
			subcategories: {
				list: {
					title: 'List of Mundo Gamer Academy Courses SubCategories',
					subTitle:
						'Check the list of Latest Courses SubCategories registered on the the Mundo Gamer Academy.',
				},
				create: {
					title: 'New Course SubCategory',
					subTitle: 'SubCategory',
				},
			},
			levels: {
				list: {
					title: 'List of Mundo Gamer Academy Courses Levels',
					subTitle: 'Check the list of Latest Courses Levels registered on the the Mundo Gamer Academy.',
				},
				create: {
					title: 'New Course Level',
					subTitle: 'Level',
				},
				update: {
					title: 'Update Course Level',
					subTitle: 'Course Level',
				},
				delete: {
					title: 'The Course Level was deleted successfully',
				},
			},
			formats: {
				list: {
					title: 'List of Mundo Gamer Academy Courses Formats',
					subTitle: 'Check the list of Latest Courses Formats registered on the the Mundo Gamer Academy.',
				},
				create: {
					title: 'New Course Format',
					subTitle: 'Course Format',
				},
				update: {
					title: 'Update Course Format',
					subTitle: 'Course Format',
				},
				delete: {
					title: 'The Course Format was deleted successfully',
				},
			},
		},
	},

	ai: {
		profiles: {
			list: {
				title: 'List of Profiles',
				subTitle: 'Check the list of Profiles registered on the all platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Profile',
				subTitle: 'Profile',
			},
			update: {
				title: 'Update Profile',
			},
			delete: {
				title: 'The Profile was deleted successfully',
			},
		},
		profilesCategories: {
			list: {
				title: 'List of Profiles Categories',
				subTitle:
					'Check the list of Profiles Categories registered on the all platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Profile Category',
				subTitle: 'Profile Category',
			},
			update: {
				title: 'Update Profile Category',
			},
			delete: {
				title: 'The Profile Category was deleted successfully',
			},
		},
		languageModel: {
			list: {
				title: 'List of Language Models',
				subTitle:
					'Check the list of Language Models registered on the all platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Language Model',
				subTitle: 'Language Model',
				message: 'To create a new language model please contact an admin',
			},
			update: {
				title: 'Update Language Model',
			},
			delete: {
				title: 'The Language Model was deleted successfully',
			},
		},
		services: {
			list: {
				title: 'List of Services',
				subTitle: 'Check the list of Services registered on the all platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Service',
				subTitle: 'Service',
			},
			update: {
				title: 'Update Service',
			},
			delete: {
				title: 'The Service was deleted successfully',
			},
		},
		tokens: {
			list: {
				title: 'List of Token Configs',
				subTitle: 'Check the list of Token Configs registered on the all platforms of the Mundo Gamer Network',
			},
			update: {
				title: 'Update Token Config',
			},
		},
	},

	agency: {
		marketing: {
			highlights: {
				list: {
					title: 'List of Mundo Gamer Community Highlights',
					subTitle: 'Check the list of Latest Highlights registered on the Mundo Gamer Community.',
				},
				create: {
					title: 'New  Highlight',
					subTitle: 'Fill in the fields below to register a new Highlight',
				},
				update: {
					title: 'Update Highlight',
				},
			},
			highlightBanners: {
				list: {
					title: 'List of latest Rotating Banners from Mundo Gamer Community App',
					subTitle: 'Check the list of Rotating Banners registered in the Mundo Gamer Community application.',
				},
				create: {
					title: 'New Highlight Banner',
					subTitle: 'Fill in the fields below to register a new Highlight Banner',
				},
				update: {
					title: 'Update Highlight Banner',
					subTitle: 'Highlight Banner',
				},
				delete: {
					title: 'The Highlight Banner was deleted successfully',
				},
			},
			tvBanners: {
				list: {
					title: 'List of Mundo Gamer TV Banners',
					subTitle: 'Check the list of latest ADS Banners registered on the Mundo Gamer TV.',
				},
				create: {
					title: 'New TV Banner',
					subTitle: 'Fill in the fields below to register a new TV Banner',
				},
				update: {
					title: 'Update TV Banner',
					subTitle: 'TV Banner',
				},
				delete: {
					title: 'The TV Banner was deleted successfully',
				},
			},
			providers: {
				list: {
					title: 'List of Mundo Gamer Agency Providers',
					subTitle: 'Check the list of Latest Providers registered on the Mundo Gamer Agency.',
				},
				create: {
					title: 'New  Provider',
					subTitle: 'Provider',
				},
				update: {
					title: 'Update Provider',
				},
				delete: {
					title: 'The Provider was deleted successfully',
				},
			},
			campaigns: {
				list: {
					title: 'List of Mundo Gamer Agency Campaigns',
					subTitle: 'Check the list of Latest Campaigns registered on the Mundo Gamer Agency.',
				},
				create: {
					title: 'New  Campaign',
					subTitle: 'Campaign',
				},
				update: {
					title: 'Update Campaign',
				},
				delete: {
					title: 'The Campaign was deleted successfully',
				},
			},
		},
		audiences: {
			list: {
				title: 'List of Mundo Gamer Agency Target Audience',
				subTitle: 'Check the list of Latest Target Audience registered on the Mundo Gamer Agency.',
			},
			create: {
				title: 'New Target Audience',
				subTitle: 'Target Audience',
			},
			update: {
				title: 'Update Target Audience',
			},
			delete: {
				title: 'The Target Audience was deleted successfully',
			},
		},
		workflows: {
			list: {
				title: 'List of Mundo Indie Workflows Status',
				subTitle: 'Check the list of Latest Workflows Status registered on the Mundo Indie.',
			},
			create: {
				title: 'New Workflow Status',
				subTitle: 'Workflow Status',
			},
			update: {
				title: 'Update Workflow Status',
			},
			delete: {
				title: 'The Workflow Status was deleted successfully',
			},
		},
		games: {
			list: {
				title: 'List of Mundo Indie Games Status',
				subTitle: 'Check the list of Latest Games Status registered on the Mundo Indie.',
			},
			create: {
				title: 'New Game Status',
				subTitle: 'Game Status',
			},
			update: {
				title: 'Update Game Status',
			},
			delete: {
				title: 'The Game Status was deleted successfully',
			},
		},
		goals: {
			list: {
				title: 'List of Mundo Indie Goals',
				subTitle: 'Check the list of Latest Goals registered on the Mundo Indie.',
			},
			create: {
				title: 'New Goal',
				subTitle: 'Goal',
			},
			update: {
				title: 'Update Goal',
			},
			delete: {
				title: 'The Goal was deleted successfully',
			},
		},
		projects: {
			list: {
				title: 'List of Mundo Indie Projects',
				subTitle: 'Check the list of Latest Projects registered on the Mundo Indie.',
			},
			create: {
				title: 'New Project',
				subTitle: 'Project',
			},
			update: {
				title: 'Update Project',
			},
			delete: {
				title: 'The Project was deleted successfully',
			},
		},
		services: {
			list: {
				title: 'List of Mundo Gamer Community Service',
				subTitle: 'Check the list of Latest Service registered on the Mundo Gamer Community.',
			},
			create: {
				title: 'New Service',
				subTitle: 'Service',
			},
			update: {
				title: 'Update Service',
			},
			delete: {
				title: 'The Service was deleted successfully',
			},
		},
		magazines: {
			list: {
				title: 'List of Mundo Gamer Agency Magazines',
				subTitle: 'Check the list of Latest Magazines registered on the Mundo Gamer Agency.',
			},
			create: {
				title: 'New  Magazine',
				subTitle: 'Magazine',
			},
			update: {
				title: 'Update Magazine',
				subTitle: 'Magazine',
			},
			delete: {
				title: 'The Magazine was deleted successfully',
			},
		},
		servicesCategories: {
			list: {
				title: 'List of Mundo Gamer Agency Services Categories',
				subTitle: 'Check the list of Latest Services Categories registered on the Mundo Gamer Agency.',
			},
			create: {
				title: 'New  Service Category',
				subTitle: 'Service Category',
			},
			delete: {
				title: 'The Service Category was deleted successfully',
			},
		},
		newsletterCampaigns: {
			list: {
				title: 'List of Mundo Gamer Agency Campaigns',
				subTitle: 'Check the list of Latest Campaigns registered on the Mundo Gamer Agency.',
			},
			create: {
				title: 'New Campaign',
				subTitle: 'Campaign',
			},
			update: {
				title: 'Update Campaign',
				subTitle: 'Campaign',
			},
			delete: {
				title: 'The Campaign was deleted successfully',
			},
		},
		newsletterResults: {
			list: {
				title: 'List of Mundo Gamer Agency Newsletters Results',
				subTitle: 'Check the list of Latest Newsletters Results registered on the Mundo Gamer Agency.',
			},
		},
		newsletterCategories: {
			list: {
				title: 'List of Mundo Gamer Agency Newsletters Categories',
				subTitle: 'Check the list of Latest Newsletters Categories registered on the Mundo Gamer Agency.',
			},
			create: {
				title: 'New  Newsletter Category',
				subTitle: 'Newsletter Category',
			},
			update: {
				title: 'Update Newsletter Category',
				subTitle: 'Newsletter Category',
			},
			delete: {
				title: 'The Newsletter Category was deleted successfully',
			},
		},
		newsletterPeriodicities: {
			list: {
				title: 'List of Mundo Gamer Agency Newsletters Periodicities',
				subTitle: 'Check the list of Latest Newsletters Periodicities registered on the Mundo Gamer Agency.',
			},
			create: {
				title: 'New  Newsletter Periodicity',
				subTitle: 'Newsletter Periodicity',
			},
			update: {
				title: 'Update Newsletter Periodicity',
				subTitle: 'Newsletter Periodicity',
			},
			delete: {
				title: 'The Newsletter Periodicity was deleted successfully',
			},
		},
		contactMessages: {
			list: {
				title: 'List of Mundo Gamer Agency Contact Requests',
				subTitle: 'Check the list of Latest Contact Requests send to the Mundo Gamer Agency.',
			},
			create: {
				title: 'Contact Request',
			},
		},
	},

	club: {
		franchiseModels: {
			list: {
				title: 'List of Mundo Gamer Club Franchise Models',
				subTitle: 'Check the list of Franchise Models registered on the the Mundo Gamer Club.',
			},
			create: {
				title: 'New Franchise Model',
				subTitle: 'Fill in the fields below to register a new Franchise Model.',
			},
			update: {
				title: 'Edit Franchise Model',
			},
		},
		contactFranchise: {
			list: {
				title: 'List of Mundo Gamer Club Contact Franchise',
				subTitle: 'Check the list of Contact Franchise registered on the the Mundo Gamer Club.',
			},
		},
	},

	community: {
		pulses: {
			pulses: {
				list: {
					title: 'List of Mundo Gamer Community Latest Pulses',
					subTitle: 'Check the list of Latest Pulses registered on the the Mundo Gamer Community.',
				},
				create: {
					title: 'New Pulse',
					subTitle: 'Fill in the fields below to publish a news item.',
					notification: 'New pulse save successfully',
				},
				delete: {
					title: 'Pulse deleted successfully',
				},
				update: {
					notification: 'New pulse updated successfully',
				},
			},
			category: {
				list: {
					title: 'List of Pulses Category',
					subTitle: 'Check the list of Pulses Categories registered on Mundo Gamer Community.',
				},
				create: {
					title: 'New Pulse Category',
					subTitle: 'Fill in the fields below to register a new Pulse Category.',
				},
				update: {
					title: 'Update Pulse Category',
					subTitle: 'Pulse Category',
				},
			},
			pending: {
				list: {
					title: 'List of Mundo Gamer Latest Pulses Revision',
					subTitle: 'Check the list of Latest Pulses Revision registered on the the Mundo Gamer Community.',
				},
				create: {
					title: 'New Pulse Pending',
					subTitle: 'Fill in the fields below to register a new Pulse Pending.',
				},
			},
			source: {
				list: {
					title: 'List of Pulses Source',
					subTitle: 'Check the list of Pulses Source registered on Mundo Gamer Community.',
				},
				create: {
					title: 'New Pulse Source',
					subTitle: 'Pulse Source',
				},
				update: {
					title: 'Update Pulse Source',
					subTitle: 'Pulse Source',
				},
			},
			status: {
				list: {
					title: 'List of Mundo Gamer Latest Pulse Status',
					subTitle: 'Check the list of Latest Pulse Status on Mundo Gamer Community',
				},
				create: {
					title: 'New Pulse Status',
					subTitle: 'Fill in the fields below to register a new Pulse Status.',
				},
			},
		},
		reviews: {
			list: {
				title: 'List of Mundo Gamer Reviews',
				subTitle: 'Check the list of Latest Reviews on Mundo Gamer Community',
			},
			create: {
				title: 'New Review',
				subTitle: 'Fill in the fields below to publish a new review.',
			},
			rating: {
				title: 'Rating',
				gameplay: 'GamePlay Rate',
				story: 'Story Rate',
				graphics: 'Graphics Rate',
				controls: 'Controls Rate',
				audio: 'Audio Rate',
			},
			delete: {
				title: 'Review deleted successfully.',
			},
			notification: {
				create: 'New Review successfully created.',
				update: 'Review was successfully updated.',
				delete: 'Review was successfully deleted.',
			},
		},
		videos: {
			list: {
				title: 'List of Mundo Gamer Videos',
				subTitle: 'Check the list of Videos Reviews on Mundo Gamer Community',
			},
			create: {
				title: 'New Video',
				subTitle: 'Fill in the fields below to publish a new video.',
			},
			delete: {
				title: 'Remove video',
			},
			notification: {
				delete: 'Did you have delete this video?',
			},
			category: {
				list: {
					title: 'List of Videos Category',
					subTitle: 'Check the list of Videos Categories registered on Mundo Gamer Community.',
				},
				create: {
					title: 'New Video Category',
					subTitle: 'Fill in the fields below to register a new Video Category.',
				},
				update: {
					title: 'Update Video Category',
					subTitle: 'Video Category',
				},
			},
		},
		schedulers: {
			list: {
				title: 'List of Mundo Gamer Schedulers',
				subTitle: 'Check the list of Schedulers on Mundo Gamer Community',
			},
			delete: {
				title: 'Scheduler deleted successfully.',
			},
		},
		awards: {
			list: {
				title: 'List of Mundo Gamer Awards',
				subTitle: 'Check the list of Awards on Mundo Gamer Community',
			},
			create: {
				title: 'New Award',
				subTitle: 'Fill in the fields below to publish a new Award.',
			},
			delete: {
				title: 'Award deleted successfully.',
			},
		},
		awardsCategories: {
			list: {
				title: 'List of Mundo Gamer Awards Categories',
				subTitle: 'Check the list of Awards Categories on Mundo Gamer Community',
			},
			create: {
				title: 'New Award Category',
				subTitle: 'Fill in the fields below to publish a new Award Category.',
			},
			delete: {
				title: 'Award Category deleted successfully.',
			},
		},
		awardsCompetitors: {
			list: {
				title: 'List of Mundo Gamer Awards Competitors',
				subTitle: 'Check the list of Awards Competitors on Mundo Gamer Community',
			},
			create: {
				title: 'New Competitor',
				subTitle: 'Fill in the fields below to publish a new Competitor.',
			},
			delete: {
				title: 'Competitor deleted successfully.',
			},
		},

		quizzes: {
			list: {
				title: 'List of Mundo Gamer Quizzes',
				subTitle: 'Check the list of Quizzes on Mundo Gamer Community',
			},
			create: {
				title: 'New Quiz',
				subTitle: 'Fill in the fields below to publish a new Quiz.',
			},
		},
		questions: {
			list: {
				title: 'List of Mundo Gamer Quizzes Questions',
				subTitle: 'Check the list of Quizzes Questions on Mundo Gamer Community',
			},
			create: {
				title: 'New Question',
				subTitle: 'Fill in the fields below to publish a new Question.',
			},
		},
		ratings: {
			list: {
				title: 'List of Mundo Gamer Ratings',
				subTitle: 'Check the list of Ratings on Mundo Gamer Community',
			},
			create: {
				title: 'Rating',
				subTitle: 'Fill in the fields below to publish a new Rating.',
			},
		},
		releases: {
			list: {
				title: 'List of Mundo Gamer Upcoming Games',
				subTitle: 'Check the list of Upcoming Games on Mundo Gamer Community',
			},
		},
		reports: {
			list: {
				title: 'List of Mundo Gamer Reports',
				subTitle: 'Check the list of Reports registered on the the Mundo Gamer Community.',
			},
			create: {
				title: 'New Report',
				subTitle: 'Report',
			},
			update: {
				title: 'Update Report',
				subTitle: 'Report',
			},
		},
		reportsCategories: {
			list: {
				title: 'List of Mundo Gamer Reports Categories',
				subTitle: 'Check the list of Reports Categories registered on the the Mundo Gamer Community.',
			},
			create: {
				title: 'New Report Category',
				subTitle: 'Report Category',
			},
			update: {
				title: 'Update Report Category',
				subTitle: 'Report Category',
			},
		},
		reportsSubcategories: {
			list: {
				title: 'List of Mundo Gamer Reports Subcategories',
				subTitle: 'Check the list of Reports Subcategories registered on the the Mundo Gamer Community.',
			},
			create: {
				title: 'New Report Subcategory',
				subTitle: 'Report Subcategory',
			},
			update: {
				title: 'Update Report Subcategory',
				subTitle: 'Report Subcategory',
			},
		},
		pushNotifications: {
			list: {
				title: 'List of Push Notifications',
				subTitle:
					'Check the list of Push Notifications registered on the platforms of the Mundo Gamer Community',
			},
			create: {
				title: 'New Push Notification',
				subTitle: 'Push Notification',
			},
		},
		esports: {
			teams: {
				list: {
					title: 'List of Mundo Gamer Teams',
					subTitle: 'Check the list of Teams registered on the the Mundo Gamer Community.',
				},
				create: {
					title: 'New Team',
					subTitle: 'Fill in the fields below to publish a new Team.',
				},
			},
		},
		communities: {
			list: {
				title: 'List of Mundo Gamer Communities',
				subTitle: 'Check the list of Communities registered on the the Mundo Gamer Community.',
			},
			update: {
				title: 'Update Community',
				subTitle: 'Fill in the fields below to update a Community.',
			},
		},
		wikigamer: {
			games: {
				achievements: {
					list: {
						title: 'List of Mundo Gamer Achievements',
						subTitle: 'Check the list of Achievements registered on the the Mundo Gamer Community.',
					},
					create: {
						title: 'New Game Achievement',
						subTitle: 'Game Achievement',
					},
				},
				types: {
					list: {
						title: 'List of Mundo Gamer Games Types',
						subTitle: 'Check the list of Games Types registered on the the Mundo Gamer Community.',
					},
					create: {
						title: 'New Game Type',
						subTitle: 'Game Type',
					},
					update: {
						title: 'Update Game Type',
						subTitle: 'Game Type',
					},
				},
				engines: {
					list: {
						title: 'List of Mundo Gamer Game Engines',
						subTitle: 'Check the list of Game Engines registered on the the Mundo Gamer Community.',
					},
					create: {
						title: 'New Engine',
						subTitle: 'Engine',
					},
					update: {
						title: 'Update Game Engine',
						subTitle: 'Game Engine',
					},
				},
				games: {
					list: {
						title: 'List of Mundo Gamer Games',
						subTitle: 'Check the list of Games registered on the the Mundo Gamer Community.',
					},
					create: {
						title: 'New Game',
						subTitle: 'Fill in the fields below to register a new Game.',
					},
					update: {
						title: 'Update Game',
						subTitle: 'Fill in the fields below to update a Game.',
					},
				},
				genres: {
					list: {
						title: 'List of Mundo Gamer Game Genres',
						subTitle: 'Check the list of Game Genres registered on the the Mundo Gamer Community.',
					},
					create: {
						title: 'New Genre',
						subTitle: 'Genre',
					},
					update: {
						title: 'Update Genre',
						subTitle: 'Genre',
					},
				},
				modes: {
					list: {
						title: 'List of Mundo Gamer Game Modes',
						subTitle: 'Check the list of Game Modes registered on the the Mundo Gamer Community.',
					},
					create: {
						title: 'New Game Mode',
						subTitle: 'Game Mode',
					},
					update: {
						title: 'Update Game Mode',
						subTitle: 'Game Mode',
					},
				},
				perspectives: {
					list: {
						title: 'List of Mundo Gamer Player Perspectives',
						subTitle: 'Check the list of Player Perspectives registered on the the Mundo Gamer Community.',
					},
					create: {
						title: 'New Perspective',
						subTitle: 'Perspective',
					},
					update: {
						title: 'Update Game Perspective',
						subTitle: 'Game Perspective',
					},
				},
				status: {
					list: {
						title: 'List of Mundo Gamer Game Status',
						subTitle: 'Check the list of Game Status registered on the the Mundo Gamer Community.',
					},
					create: {
						title: 'New Status',
						subTitle: 'Status',
					},
					update: {
						title: 'Update Game Status',
						subTitle: 'Game Status',
					},
				},
				themes: {
					list: {
						title: 'List of Mundo Gamer Game Themes',
						subTitle: 'Check the list of Game Themes registered on the the Mundo Gamer Community.',
					},
					create: {
						title: 'New Theme',
						subTitle: 'Theme',
					},
				},
				websites: {
					list: {
						title: 'List of Mundo Gamer Game Websites',
						subTitle: 'Check the list of Game Websites registered on the the Mundo Gamer Community.',
					},
					create: {
						title: 'New Website',
						subTitle: 'Website',
					},
					update: {
						title: 'Update Website',
						subTitle: 'Website',
					},
				},
				rating: {
					esrb: {
						list: {
							title: 'List of Mundo Gamer ESRB',
							subTitle: 'Check the list of ESRB registered on the the Mundo Gamer Community.',
						},
						create: {
							title: 'New ESRB',
							subTitle: 'ESRB',
						},
					},
					pegi: {
						list: {
							title: 'List of Mundo Gamer Pegi',
							subTitle: 'Check the list of Pegi registered on the the Mundo Gamer Community.',
						},
						create: {
							title: 'New Pegi',
							subTitle: 'Pegi',
						},
					},
				},
			},
			eventsCategories: {
				list: {
					title: 'List of Mundo Gamer Events Categories',
					subTitle: 'Check the list of Latest Events Categories registered on the the Mundo Gamer Community.',
				},
				create: {
					title: 'New Event Category',
					subTitle: 'Event Category',
				},
				update: {
					title: 'Update Event Category',
					subTitle: 'Event Category',
				},
			},
			events: {
				list: {
					title: 'List of Mundo Gamer Events',
					subTitle: 'Check the list of Latest Events registered on the the Mundo Gamer Community.',
				},
				create: {
					title: 'New Event',
					subTitle: 'Fill in the fields below to register a new Event.',
				},
			},
			regions: {
				list: {
					title: 'List of Mundo Gamer Regions',
					subTitle: 'Check the list of Latest Regions registered on the the Mundo Gamer Community.',
				},
				create: {
					title: 'New Region',
					subTitle: 'Region',
				},
			},
			mediaTypes: {
				list: {
					title: 'List of Mundo Gamer Media Types',
					subTitle: 'Check the list of Latest Media Types registered on the the Mundo Gamer Community.',
				},
				create: {
					title: 'New Media Type',
					subTitle: 'Media Type',
				},
				update: {
					title: 'Update Media Type',
					subTitle: 'Media Type',
				},
			},
			franchises: {
				list: {
					title: 'List of Mundo Gamer Franchises',
					subTitle: 'Check the list of Franchises registered on the the Mundo Gamer Community.',
				},
				create: {
					title: 'New Franchise',
					subTitle: 'Fill in the fields below to register a new Franchise.',
				},
				update: {
					title: 'Update Franchise',
					subTitle: 'Fill in the fields below to update a Franchise.',
				},
			},
			collections: {
				list: {
					title: 'List of Mundo Gamer Collections',
					subTitle: 'Check the list of Collections registered on the the Mundo Gamer Community.',
				},
				create: {
					title: 'New Collection',
					subTitle: 'Fill in the fields below to register a new Collection.',
				},
				update: {
					title: 'Update Collection',
					subTitle: 'Fill in the fields below to register a update Collection.',
				},
			},

			genders: {
				list: {
					title: 'List of Mundo Gamer Genders',
					subTitle: 'Check the list of Latest Genders registered on the the Mundo Gamer Community.',
				},
				create: {
					title: 'New Gender',
					subTitle: 'Gender',
				},
				update: {
					title: 'Update Gender',
					subTitle: 'Gender',
				},
			},

			phrases: {
				list: {
					title: 'List of Mundo Gamer Phrases',
					subTitle: 'Check the list of Latest Phrases registered on the the Mundo Gamer Community.',
				},
				create: {
					title: 'New Phrase',
					subTitle: 'Phrase',
				},
				update: {
					title: 'Update Phrase',
					subTitle: 'Phrase',
				},
			},

			species: {
				list: {
					title: 'List of Mundo Gamer Species',
					subTitle: 'Check the list of Latest Species registered on the the Mundo Gamer Community.',
				},
				create: {
					title: 'New Specie',
					subTitle: 'Specie',
				},
				update: {
					title: 'Update Specie',
					subTitle: 'Specie',
				},
			},
			characters: {
				list: {
					title: 'List of Mundo Gamer Characters',
					subTitle: 'Check the list of Latest Characters registered on the the Mundo Gamer Community.',
				},
				create: {
					title: 'New Character',
					subTitle: 'Fill in the fields below to register a new Character.',
				},
				update: {
					title: 'Update Character',
					subTitle: 'Fill in the fields below to edit a Character.',
				},
			},
			characterStatus: {
				list: {
					title: 'List of Mundo Gamer Character Status',
					subTitle: 'Check the list of Latest Character Status registered on the the Mundo Gamer Community.',
				},
				create: {
					title: 'New Character Status',
					subTitle: 'Character Status',
				},
				update: {
					title: 'Update Character Status',
					subTitle: 'Character Status',
				},
			},
			characterStatusWebsite: {
				list: {
					title: 'List of Mundo Gamer Character Website Status',
					subTitle:
						'Check the list of Latest Character Website Status registered on the the Mundo Gamer Community.',
				},
				create: {
					title: 'Character Website Status',
				},
			},
			achievements: {
				list: {
					title: 'List of Mundo Gamer Achievements',
					subTitle: 'Check the list of Latest Achievements registered on the the Mundo Gamer Community.',
				},
				create: {
					title: 'New Achievement',
					subTitle: 'Fill in the fields below to register a new Achievement.',
				},
				update: {
					title: 'Update Achievement',
					subTitle: 'Achievement',
				},
				delete: {
					title: 'Achievement deleted successfully',
				},
			},
			platforms: {
				list: {
					title: 'List of Mundo Gamer Platforms',
					subTitle: 'Check the list of Latest Platforms registered on the the Mundo Gamer Community.',
				},
				create: {
					title: 'New Platform',
					subTitle: 'Fill in the fields below to register a new Platform.',
				},
			},
			platformsType: {
				list: {
					title: 'List of Mundo Gamer Platforms Types',
					subTitle: 'Check the list of Latest Platforms Types registered on the the Mundo Gamer Community.',
				},
				update: {
					title: 'Update Platform Type',
				},
				create: {
					title: 'New Platform Type',
					subTitle: 'Platform Type',
				},
			},
			platformsFamily: {
				list: {
					title: 'List of Mundo Gamer Platforms Family',
					subTitle: 'Check the list of Latest Platforms Family registered on the the Mundo Gamer Community.',
				},
				update: {
					title: 'Update Platform Family',
				},
				create: {
					title: 'New Platform Family',
					subTitle: 'Platform Family',
				},
			},
			platformsGenerations: {
				list: {
					title: 'List of Mundo Gamer Platforms Generations',
					subTitle:
						'Check the list of Latest Platforms Generations registered on the the Mundo Gamer Community.',
				},
				update: {
					headLine: 'Update a platform generation',
					title: 'Update item completed successfully',
				},
				create: {
					title: 'New Platform Generation',
					subTitle: 'Platform Generation',
					notification: 'New generation save successfully',
				},
				delete: {
					title: 'item deleted successfully',
				},
			},
		},
		translationQueue: {
			contentType: 'Content Type',
			contentTypeId: 'Content Id',
			list: {
				title: 'List of Queued Translations',
				subTitle: 'Check the list of Queued Translations',
			},
			create: {
				title: 'Add Translation to the Queue',
				subTitle: 'Translation',
			},
			update: {
				title: 'Update Queued Translation',
				subTitle: 'Translation',
			},
			refresh: {
				button: 'Retranslate',
			},
			modal: {
				title: 'Translation Error',
			},
			notification: {
				create: 'Translation added to the queue successfully',
				update: 'Queued Translation updated successfully',
				reset: 'Queued Translation reset successfully',
				run: 'Translation executed successfully',
				delete: 'Translation was successfully removed from the queue.',
			},
		},
	},
	jobs: {
		vacancies: {
			list: {
				title: 'List of Mundo Gamer Vacancies',
				subTitle: 'Check the list of Vacancies registered on the Mundo Gamer Jobs.',
			},
			create: {
				title: 'New Vacancy',
				subTitle: 'Fill in the fields below to publish a new Vacancy.',
			},
		},
		vacanciesStatus: {
			list: {
				title: 'List of Mundo Gamer Vacancies Status',
				subTitle: 'Check the list of Vacancies Status registered on the Mundo Gamer Jobs.',
			},
			create: {
				title: 'New Vacancy Status',
				subTitle: 'Vacancy Status',
			},
			delete: {
				title: 'The Vacancy Status was deleted successfully',
			},
		},
		resumes: {
			list: {
				title: 'List of Mundo Gamer Resumes',
				subTitle: 'Check the list of Resumes registered on the Mundo Gamer Jobs.',
			},
			create: {
				title: 'New Resume',
				subTitle: 'Fill in the fields below to publish a new Resume.',
			},
		},
		applications: {
			list: {
				title: 'List of Mundo Gamer Applications',
				subTitle: 'Check the list of Applications registered on the Mundo Gamer Jobs.',
			},
		},
		companiesPerks: {
			list: {
				title: 'List of Mundo Gamer Companies Perks',
				subTitle: 'Check the list of Companies Perks registered on the Mundo Gamer Jobs.',
			},
			create: {
				title: 'New Company Perk',
				subTitle: 'Company Perk',
			},
			delete: {
				title: 'The Company Perk was deleted successfully',
			},
		},
		employmentsTypes: {
			list: {
				title: 'List of Mundo Gamer Employments Types',
				subTitle: 'Check the list of Employments Types registered on the Mundo Gamer Jobs.',
			},
			create: {
				title: 'New Employment Type',
				subTitle: 'Employment Type',
			},
			delete: {
				title: 'The Employment Type was deleted successfully',
			},
		},
		experiences: {
			list: {
				title: 'List of Mundo Gamer Experiences',
				subTitle: 'Check the list of Experiences registered on the Mundo Gamer Jobs.',
			},
			create: {
				title: 'New Experience',
				subTitle: 'Experience',
			},
			delete: {
				title: 'The Experience was deleted successfully',
			},
		},
		applicationMethods: {
			list: {
				title: 'List of Mundo Gamer Application Methods',
				subTitle: 'Check the list of Application Methods registered on the Mundo Gamer Jobs.',
			},
			create: {
				title: 'New Application Method',
				subTitle: 'Application Method',
			},
			delete: {
				title: 'The Application Method was deleted successfully',
			},
		},
		applicationStatus: {
			list: {
				title: 'List of Mundo Gamer Application Status',
				subTitle: 'Check the list of Application Status registered on the Mundo Gamer Jobs.',
			},
			create: {
				title: 'New Application Status',
				subTitle: 'Application Status',
			},
			delete: {
				title: 'The Application Status was deleted successfully',
			},
		},
		educationLevels: {
			list: {
				title: 'List of Mundo Gamer Education Levels',
				subTitle: 'Check the list of Education Levels registered on the Mundo Gamer Jobs.',
			},
			create: {
				title: 'New Education Level',
				subTitle: 'Education Level',
			},
			delete: {
				title: 'The Education Level was deleted successfully',
			},
		},
		languageLevels: {
			list: {
				title: 'List of Mundo Gamer Language Levels',
				subTitle: 'Check the list of Language Levels registered on the Mundo Gamer Jobs.',
			},
			create: {
				title: 'New Language Level',
				subTitle: 'Language Level',
			},
			delete: {
				title: 'The Language Level was deleted successfully',
			},
		},
		professionalSituations: {
			list: {
				title: 'List of Mundo Gamer Professional Situations',
				subTitle: 'Check the list of Professional Situations registered on the Mundo Gamer Jobs.',
			},
			create: {
				title: 'New Professional Situation',
				subTitle: 'Professional Situation',
			},
			delete: {
				title: 'The Professional Situation was deleted successfully',
			},
		},
		workHours: {
			list: {
				title: 'List of Mundo Gamer Work Hours',
				subTitle: 'Check the list of Work Hours registered on the Mundo Gamer Jobs.',
			},
			create: {
				title: 'New Work Hour',
				subTitle: 'Work Hour',
			},
			delete: {
				title: 'The Work Hour was deleted successfully',
			},
		},
		workMethodologies: {
			list: {
				title: 'List of Mundo Gamer Work Methodologies',
				subTitle: 'Check the list of Work Methodologies registered on the Mundo Gamer Jobs.',
			},
			create: {
				title: 'New Work Methodology',
				subTitle: 'Work Methodology',
			},
			delete: {
				title: 'The Work Methodology was deleted successfully',
			},
		},
		workAvailabilities: {
			list: {
				title: 'List of Mundo Gamer Work Availabilities',
				subTitle: 'Check the list of Work Availabilities registered on the Mundo Gamer Jobs.',
			},
			create: {
				title: 'New Work Availability',
				subTitle: 'Work Availability',
			},
			delete: {
				title: 'The Work Availability was deleted successfully',
			},
		},
	},

	network: {
		institutional: {
			mgCompanies: {
				list: {
					title: 'Mundo Gamer Companies List',
					subTitle: 'Check the list of Companies registered on Mundo Gamer Network',
				},
				create: {
					title: 'Add new Company',
					subTitle: 'Fill in the fields below to register a new Company.',
				},
				update: {
					title: 'Update company information',
					subTitle: 'Fill the fields below to update company information',
				},
				accounts: {
					noData: 'There are no accounts registered with this company :(',
					list: {
						title: 'List of Finance Accounts',
						subtitle: 'Check the list of financial accounts registered on your business',
					},
					update: {
						title: 'Update your bank account',
					},
					create: {
						title: 'New Account',
						subTitle: 'Account',
					},
					notification: 'Action performed successfully',
				},
			},
			apis: {
				list: {
					title: 'List of APIs',
					subTitle: 'Check the list of APIs registered on Mundo Gamer Network',
				},
				create: {
					title: 'Add New API Credentials',
					subTitle: 'Credentials',
				},
			},
			credentials: {
				list: {
					title: 'List of Credentials',
					subTitle: 'Check the list of credentials registered on Mundo Gamer Network',
				},
				create: {
					title: 'Add new API Credential',
					subTitle: 'Fill the fields below to register a Credential.',
				},
				update: {
					title: 'Update a Credential',
				},
				notification: {
					create: 'New Credential successfully created.',
					update: 'Credential was successfully updated.',
					delete: 'Credential was successfully deleted.',
				},
			},

			companiesType: {
				list: {
					title: 'List of Mundo Gamer Types of Company Categories',
					subTitle: 'Check the list of Latest Types of Company Categories registered on the the Mundo Gamer.',
				},
				create: {
					title: 'New Type of Company Category',
					subTitle: 'Type of Company Category',
				},
				delete: {
					title: 'The Type of Company Category was deleted successfully',
				},
			},

			companiesSize: {
				list: {
					title: 'List of Mundo Gamer Company Sizes',
					subTitle: 'Check the list of Latest Company Sizes registered on the the Mundo Gamer.',
				},
				create: {
					title: 'New Company Size',
					subTitle: 'Company Size',
				},
				delete: {
					title: 'The Company Size was deleted successfully',
				},
			},
		},
		blog: {
			posts: {
				list: {
					title: 'List of Posts',
					subTitle: 'Check the list of posts registered on Mundo Gamer Network',
				},
				create: {
					title: 'New Blog Post',
					subTitle: 'Fill in the fields below to publish a new Blog Post.',
				},
				update: {
					title: 'Update Blog Post',
				},
			},
			categories: {
				list: {
					title: 'List of Categories',
					subTitle: 'Check the list of categories registered on Mundo Gamer Network',
				},
				create: {
					title: 'New Blog Category',
					subTitle: 'Fill in the fields below to register a new Blog Categorie.',
				},
			},
			tags: {
				list: {
					title: 'List of Tags',
					subTitle: 'Check the list of tags registered on the platforms of the Mundo Gamer Network',
				},
			},
		},
		careers: {
			list: {
				title: 'List of Careers',
				subTitle: 'Check the list of careers registered on the platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Career',
				subTitle: 'Fill in the fields below to register a new Career.',
			},
			applications: {
				list: {
					title: 'List of Careers Applications',
					subTitle: 'Check the list of applications registered on the Mundo Gamer Network platform',
				},
				create: {
					title: 'New Application',
					subTitle: 'Fill in the fields below to register a new Application.',
				},
				filter: {
					show: {
						label: 'Filter by',
						all: 'All',
						favorite: 'Favorites',
						notFavorite: 'Not Favorite',
						career: 'Career',
						name: 'Name',
					},
				},
			},
			skills: {
				list: {
					title: 'List of Careers Skills',
					subTitle: 'Check the list of Skills registered on the Mundo Gamer Network platform',
				},
				create: {
					title: 'New Skill',
					subTitle: 'Fill in the fields below to register a new Skill.',
				},
			},
		},
		industrySectors: {
			list: {
				title: 'List of Mundo Gamer Industry Sector Categories',
				subTitle: 'Check the list of Industry Sector Categories registered on the the Mundo Gamer.',
			},
			create: {
				title: 'New Industry Sector Category',
				subTitle: 'Industry Sector Category',
			},
			delete: {
				title: 'The Industry Sector Category was deleted successfully',
			},
		},
		socialiteProviders: {
			list: {
				title: 'List of Mundo Gamer Network Socialite Providers',
				subTitle: 'Check the list of Socialite Providers registered on the the Mundo Gamer Network.',
			},
			create: {
				title: 'New Socialite Provider',
				subTitle: 'Fill in the fields below to register a new Socialite Provider.',
			},
			update: {
				title: 'Edit Socialite Provider',
			},
		},
		languages: {
			list: {
				title: 'List of Languages',
				subTitle: 'Check the list of Languages registered on the platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Language',
				subTitle: 'Language',
			},
			update: {
				title: 'Update a language',
			},
			delete: {
				title: 'Language deleted successfully',
			},
			notification: {
				create: 'New language successfully registered.',
				update: 'Language updated successfully.',
				delete: 'Are you sure you want to delete this language?',
			},
		},
		cities: {
			list: {
				title: 'List of Cities',
				subTitle: 'Check the list of Cities registered on the platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New City',
				subTitle: 'Fill in the fields below to register a new City.',
			},
			delete: {
				title: 'The City was deleted successfully',
			},
		},
		countries: {
			list: {
				title: 'List of Countries',
				subTitle: 'Check the list of Countries registered on the platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Country',
				subTitle: 'Fill in the fields below to register a new Country.',
			},
		},
		nationalities: {
			list: {
				title: 'List of Nationalities',
				subTitle: 'Check the list of Nationalities registered on the platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Nationality',
				subTitle: 'Nationality',
			},
		},
		regions: {
			list: {
				title: 'List of Regions',
				subTitle: 'Check the list of Regions registered on the platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Region',
				subTitle: 'Fill in the fields below to register a new Region.',
			},
			delete: {
				title: 'The Region was deleted successfully',
			},
		},
		states: {
			list: {
				title: 'List of States',
				subTitle: 'Check the list of States registered on the platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New State',
				subTitle: 'Fill in the fields below to register a new State.',
			},
			delete: {
				title: 'The State was deleted successfully',
			},
		},
		timezones: {
			list: {
				title: 'List of Timezones',
				subTitle: 'Check the list of Timezones registered on the platforms of the Mundo Gamer Network',
			},
		},
		operationalArea: {
			list: {
				title: 'List of Operational Areas',
				subTitle: 'Check the list of operational areas registered on the platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Operational Area',
				subTitle: 'Operational Area',
			},
			update: {
				title: 'Update an Operational Area',
			},
			delete: {
				title: 'The Operational Area: {item}, was deleted successfully',
			},
			notification: {
				create: 'The new Operational area: {item}. Was created successfully',
				update: 'The Operational Area: {item}. Was successfully updated!',
				delete: 'Are you sure you want to delete this operational area?',
			},
		},
		networkPlatforms: {
			list: {
				title: 'List of Mundo Gamer Platforms',
				subTitle: 'Check the list of Platforms registered on the Mundo Gamer Network',
			},
			create: {
				title: 'New Platform',
				subTitle: 'MG Platform',
			},
			delete: {
				title: 'The Platform was deleted successfully',
			},
		},
		workflows: {
			list: {
				title: 'List of Mundo Gamer Community Workflows Status',
				subTitle: 'Check the list of Latest Workflows Status registered on the Mundo Gamer Community.',
			},
			create: {
				title: 'New Workflow Status',
				subTitle: 'Workflow Status',
			},
			update: {
				title: 'Update Workflow Status',
			},
			delete: {
				title: 'The Workflow Status was deleted successfully',
			},
		},
		faqsCategories: {
			list: {
				title: 'List of FAQs Categories',
				subTitle:
					'Check the list of FAQs Categories registered on the all platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New FAQ Category',
				subTitle: 'FAQ Category',
			},
			delete: {
				title: 'The FAQ Category was deleted successfully',
			},
		},
		benefits: {
			list: {
				title: 'List of Plans Benefits',
				subTitle: 'Check the list of Plans Benefits registered on the all platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Benefit',
				subTitle: 'Plan Benefit',
			},
			delete: {
				title: 'The Plan Benefit was deleted successfully',
			},
		},
		plans: {
			list: {
				title: 'List of Plans',
				subTitle: 'Check the list of Plans registered on the all platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Plan',
				subTitle: 'Fill in the fields below to register a new Plan.',
			},
		},
		members: {
			list: {
				title: 'List of Team Members',
				subTitle: 'Check the list of Team Members registered on the all platforms of the Mundo Gamer Network',
				allMembers: 'All Members',
			},
			create: {
				title: 'New Team Member',
				subTitle: 'Team Member',
			},
			update: {
				title: 'Update Team Member',
			},
		},
		listProject: {
			list: {
				title: 'List of Mundo Gamer Projects',
				subTitle: 'Check the list of projects registered on the platforms of the Mundo Gamer Network.',
			},
			create: {
				title: 'New Project',
				subTitle: 'Project',
			},
			notification: {
				create: 'New project successfully registered.',
				update: 'Project updated successfully.',
			},
		},
		keysList: {
			filter: {
				show: {
					label: 'Show',
					all: 'All',
					missing: 'Keys with missing translation',
				},
				organize: {
					label: 'Sort by',
					all: 'All',
					newest: 'New',
				},
				project: {
					all: 'All projects',
					label: 'Project',
				},
			},
			list: {
				title: 'List of Mundo Gamer Keys',
				subTitle: 'Check the list of Keys registered on the platforms of the Mundo Gamer Network.',
			},
			create: {
				title: 'New Key',
				subTitle: 'Key',
			},
			update: {
				title: 'Update a key',
			},
			delete: {
				title: 'Key deleted successfully',
			},
			notification: {
				create: 'New key successfully registered.',
				update: 'Key updated successfully.',
				delete: 'Are you sure you want to delete this key?',
			},
		},
		partners: {
			list: {
				title: 'List of Partners',
				subTitle: 'Check the list of Partners registered on the platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Partner',
			},
			update: {
				title: 'Edit Partner',
			},
			delete: {
				title: 'The Partner was deleted successfully',
			},
		},
		partnersCategories: {
			list: {
				title: 'List of Partners Categories',
				subTitle:
					'Check the list of Partners Categories registered on the platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Partner Category',
				subTitle: 'Partner Category',
			},
			delete: {
				title: 'The Partner Category was deleted successfully',
			},
		},
		permissions: {
			list: {
				title: 'List of Permissions',
				subTitle: 'Check the list of Permissions registered on the platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Permission',
				subTitle: 'Permission',
			},
		},
		roles: {
			list: {
				title: 'List of Roles',
				subTitle: 'Check the list of Roles registered on the platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Role',
				subTitle: 'Role',
			},
			update: {
				title: 'Update Role',
				notification: 'Role updated successfully',
			},
		},
		documentsWebsitesVersion: {
			list: {
				title: 'List of Documents Websites Version',
				subTitle:
					'Check the list of Documents Websites Version registered on the platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Document Website Version',
				subTitle: 'Document Website Version',
			},
			delete: {
				title: 'The Document Website Version was deleted successfully',
			},
		},
		documentsTypes: {
			list: {
				title: 'List of Documents Types',
				subTitle: 'Check the list of Documents Types registered on the platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Document Type',
				subTitle: 'Document Type',
			},
			delete: {
				title: 'The Document Type was deleted successfully',
			},
		},
		documentsWebsites: {
			list: {
				title: 'List of Documents Websites',
				subTitle: 'Check the list of Documents Websites registered on the platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Document Website',
				subTitle: 'Document Website',
			},
			delete: {
				title: 'The Document Website was deleted successfully',
			},
		},
		dayTips: {
			list: {
				title: 'List of Tips of the day',
				subTitle: 'Check the list of Tips of the day registered on the platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Tip of the day',
				subTitle: 'Tip of the day',
			},
			delete: {
				title: 'The Tip of the day was deleted successfully',
			},
		},
		domains: {
			list: {
				title: 'List of Domains',
				subTitle: 'Check the list of Domains registered on the platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Domain',
				subTitle: 'Domain',
				notification: 'New domain successfully registered',
			},
			update: {
				title: 'Update a Domain',
				notification: 'Domain updated successfully',
			},
			delete: {
				title: 'The Domain was deleted successfully',
			},
		},
		roadmap: {
			list: {
				title: 'List of Roadmaps',
				subTitle: 'Check the list of Roadmaps registered on the platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Roadmap',
				subTitle: 'Fill in the fields below to register a new Roadmap.',
			},
			update: {
				title: 'Update a Roadmap',
				notification: 'Roadmap updated successfully',
			},
		},
		roadmapStatus: {
			list: {
				title: 'List of  Roadmaps Status',
				subTitle: 'Check the list of Roadmaps Status registered on the platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Roadmap Status',
				subTitle: 'Fill in the fields below to register a new Roadmap Status.',
			},
			delete: {
				title: 'The Roadmap Status was deleted successfully',
			},
		},
		users: {
			list: {
				title: 'List of Users',
				subTitle: 'Check the list of Users registered on the platforms of the Mundo Gamer Network',
			},
			edit: {
				title: 'Edit User',
				subTitle: 'User',
			},
			genders: {
				list: {
					title: 'List of Mundo Gamer Genders',
					subTitle: 'Check the list of Genders registered on the Mundo Gamer Network',
				},
				create: {
					title: 'New Gender',
					subTitle: 'Gender',
				},
				delete: {
					title: 'The Gender, was deleted successfully',
				},
			},
		},
		pressMedia: {
			pressPosts: {
				list: {
					title: 'List of Press Posts',
					subTitle: 'Check the list of Press Posts registered on the platforms of the Mundo Gamer Network',
				},
				create: {
					title: 'New Press Post',
					subTitle: 'Fill in the fields below to register a new Press Post.',
				},
				update: {
					title: 'Update Press Post',
					subTitle: 'Fill in the fields below to update a Press Post.',
				},
			},
			mediaFiles: {
				list: {
					title: 'List of Media Files',
					subTitle: 'Check the list of Media Files registered on the platforms of the Mundo Gamer Network',
				},
				create: {
					title: 'New Media File',
					subTitle: 'Fill in the fields below to register a new Media File.',
				},
			},
		},
		financial: {
			banks: {
				list: {
					title: 'List of Mundo Gamer Banks',
					subTitle: 'Check the list of Banks registered on the Mundo Gamer Network',
				},
				create: {
					title: 'New Bank',
				},
			},
			cupons: {
				list: {
					title: 'List of Mundo Gamer Discount coupons',
					subTitle: 'Check the list of Discount coupons registered on the Mundo Gamer Network',
				},
				update: {
					title: 'Update a Discount coupon',
				},
				create: {
					title: 'New Discount coupon',
				},
				delete: {
					title: 'The Discount coupon was deleted successfully',
				},
			},
			currencies: {
				list: {
					title: 'List of Mundo Gamer Currencies',
					subTitle: 'Check the list of Currencies registered on the Mundo Gamer Network',
				},
				create: {
					title: 'New Currency',
					subTitle: 'Currency',
				},
			},
			expensesCategories: {
				list: {
					title: 'List of Mundo Gamer Expenses Categories',
					subTitle: 'Check the list of Expenses Categories registered on the Mundo Gamer Network',
				},
				create: {
					title: 'New Expense Category',
					subTitle: 'Expenses Category',
				},
				delete: {
					title: 'The Expense Category was deleted successfully',
				},
			},
			invoices: {
				list: {
					title: 'List of Mundo Gamer Invoices',
					subTitle: 'Check the list of Invoices registered on the Mundo Gamer Network',
				},
				create: {
					title: 'New Invoice',
					subTitle: 'Fill in the fields below to register a new Invoice.',
				},
			},
			paymentMethods: {
				list: {
					title: 'List of Mundo Gamer Payment Methods',
					subTitle: 'Check the list of Payment Methods registered on the Mundo Gamer Network',
				},
				create: {
					title: 'New Payment Method',
					subTitle: 'Payment Method',
				},
				delete: {
					title: 'The Payment Method, was deleted successfully',
				},
			},
			paymentPeriods: {
				list: {
					title: 'List of Mundo Gamer Payment Periods',
					subTitle: 'Check the list of Payment Periods registered on the Mundo Gamer Network',
				},
				create: {
					title: 'New Payment Period',
					subTitle: 'Payment Period',
				},
				delete: {
					title: 'The Payment Period, was deleted successfully',
				},
			},
			paymentFees: {
				list: {
					title: 'List of Mundo Gamer Payment Fees',
					subTitle: 'Check the list of Payment Fees registered on the Mundo Gamer Network',
				},
				create: {
					title: 'New Payment Fee',
					subTitle: 'Payment Fee',
				},
				delete: {
					title: 'The Payment Fee, was deleted successfully',
				},
			},
			paymentRefunds: {
				list: {
					title: 'List of Mundo Gamer Payment Refunds',
					subTitle: 'Check the list of Payment Refunds registered on the Mundo Gamer Network',
				},
				create: {
					title: 'New Payment Refund',
					subTitle: 'Payment Refund',
				},
				delete: {
					title: 'The Payment Refund, was deleted successfully',
				},
			},
			paymentStatus: {
				list: {
					title: 'List of Mundo Gamer Payment Status',
					subTitle: 'Check the list of Payment Status registered on the Mundo Gamer Network',
				},
				create: {
					title: 'New Payment Status',
					subTitle: 'Payment Status',
				},
				delete: {
					title: 'The Payment Status, was deleted successfully',
				},
			},
			paymentTaxes: {
				list: {
					title: 'List of Mundo Gamer Payment Taxes',
					subTitle: 'Check the list of Payment Taxes registered on the Mundo Gamer Network',
				},
				create: {
					title: 'New Payment Tax',
					subTitle: 'Payment Tax',
				},
				delete: {
					title: 'The Payment Tax, was deleted successfully',
				},
			},
			paymentTypes: {
				list: {
					title: 'List of Mundo Gamer Payment Types',
					subTitle: 'Check the list of Payment Types registered on the Mundo Gamer Network',
				},
				create: {
					title: 'New Payment Type',
					subTitle: 'Payment Type',
				},
				delete: {
					title: 'The Payment Type, was deleted successfully',
				},
			},
		},
		products: {
			list: {
				title: 'List of Products',
				subTitle: 'Check the list of Products registered on the platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Product',
				subTitle: 'Fill in the fields below to register a new Product.',
			},
			update: {
				title: 'Update a Product',
				notification: 'Product updated successfully',
			},
		},
		productBundles: {
			list: {
				title: 'List of Product Bundles',
				subTitle: 'Check the list of Product Bundles registered on the platforms of the Mundo Gamer Network',
			},
			create: {
				title: 'New Product Bundle',
				subTitle: 'Fill in the fields below to register a new Product Bundle.',
			},
			update: {
				title: 'Update a Product Bundle',
				notification: 'Product Bundle updated successfully',
			},
		},
	},

	shop: {
		categories: {
			list: {
				title: 'List of Mundo Gamer Categories',
				subTitle: 'Check the list of Categories registered on the Mundo Gamer Shop.',
			},
			create: {
				title: 'New Category',
				subTitle: 'Category',
			},
		},
		subcategories: {
			list: {
				title: 'List of Mundo Gamer Subcategories',
				subTitle: 'Check the list of Subcategories registered on the Mundo Gamer Shop.',
			},
			create: {
				title: 'New Subcategory',
				subTitle: 'Subcategory',
			},
		},
	},

	tv: {
		banners: {
			rotatingBanners: {
				list: {
					title: 'List of Mundo Gamer TV Rotating Banners',
					subTitle: 'Check the list of Latest Rotating Banners registered on the Mundo Gamer TV.',
				},
				create: {
					title: 'New Rotating Banner',
					subTitle: 'Fill in the fields below to register a new Rotating Banner',
				},
				update: {
					title: 'Update Rotating Banner',
					subTitle: 'Rotating Banner',
				},
				delete: {
					title: 'The Rotating Banner was deleted successfully',
				},
			},
		},
		categories: {
			list: {
				title: 'List of Mundo Gamer Categories',
				subTitle: 'Check the list of Categories registered on the Mundo Gamer TV.',
			},
			create: {
				title: 'New Category',
				subTitle: 'Category',
			},
			delete: {
				title: 'The Category was deleted successfully',
			},
		},
		subcategories: {
			list: {
				title: 'List of Mundo Gamer Subcategories',
				subTitle: 'Check the list of Subcategories registered on the Mundo Gamer TV.',
			},
			create: {
				title: 'New Subcategory',
				subTitle: 'Subcategory',
			},
			delete: {
				title: 'The Subcategory was deleted successfully',
			},
		},
		streamers: {
			list: {
				title: 'List of Mundo Gamer Streamers',
				subTitle: 'Check the list of Streamers registered on the Mundo Gamer TV.',
			},
			create: {
				title: 'New Streamer',
				subTitle: 'Streamer',
			},
			delete: {
				title: 'The Streamer was deleted successfully',
			},
		},
		streamersApplications: {
			list: {
				title: 'List of Mundo Gamer streamers Applications',
				subTitle: 'Check the list of streamers Applications registered on the Mundo Gamer TV.',
			},
			create: {
				title: 'New Streamer Application',
				subTitle: 'Streamer Application',
			},
			delete: {
				title: 'The Streamer Application was deleted successfully',
			},
			applicationType: 'Application Type',
			official: 'Official',
			partner: 'Partner',
		},
	},

	Dashboards: 'Dashboards',
	CRM: 'CRM',
	Analytics: 'Analytics',
	eCommerce: 'eCommerce',
	Calendar: 'Calendar',
	Chat: 'Chat',
	Email: 'Email',
	professionalEmail: 'Professional Email',
	Invoice: 'Invoice',
	List: 'List',
	Preview: 'Preview',
	Edit: 'Edit',
	Add: 'Add',

	user: 'User',
	users: 'Users',

	'User List': 'User List',
	'User View': 'User View',
	'APPS AND PAGES': 'APPS AND PAGES',
	Pages: 'Pages',
	Authentication: 'Authentication',
	'Login V1': 'Login V1',
	'Login V2': 'Login V2',
	'Register V1': 'Register V1',
	'Register V2': 'Register V2',
	'Forgot Password V1': 'Forgot Password V1',
	'Forgot Password V2': 'Forgot Password V2',
	'Reset Password V1': 'Reset Password V1',
	'Reset Password V2': 'Reset Password V2',
	Miscellaneous: 'Miscellaneous',
	'Under Maintenance': 'Under Maintenance',
	Error: 'Error',
	'Coming Soon': 'Coming Soon',
	'Not Authorized': 'Not Authorized',
	'Knowledge Base': 'Knowledge Base',
	'Account Settings': 'Account Settings',
	Pricing: 'Pricing',
	Payments: 'Payments',
	FAQ: 'FAQ',
	'USER INTERFACE': 'USER INTERFACE',
	Typography: 'Typography',
	Icons: 'Icons',
	Gamification: 'Gamification',
	Cards: 'Cards',
	Basic: 'Basic',
	Statistics: 'Statistics',
	Advance: 'Advance',
	Actions: 'Actions',
	Chart: 'Chart',
	Components: 'Components',
	Alert: 'Alert',
	Avatar: 'Avatar',
	Badge: 'Badge',
	Button: 'Button',
	Menu: 'Menu',
	'Expansion Panel': 'Expansion Panel',
	Dialog: 'Dialog',
	Pagination: 'Pagination',
	Tabs: 'Tabs',
	Tooltip: 'Tooltip',
	Chip: 'Chip',
	Snackbar: 'Snackbar',
	Stepper: 'Stepper',
	Timeline: 'Timeline',
	Treeview: 'Treeview',
	'FORMS & TABLES': 'FORMS & TABLES',
	'Forms Elements': 'Forms Elements',
	Autocomplete: 'Autocomplete',
	Checkbox: 'Checkbox',
	Combobox: 'Combobox',
	'File Input': 'File Input',
	Radio: 'Radio',
	'Range Slider': 'Range Slider',
	Select: 'Select',
	Slider: 'Slider',
	Switch: 'Switch',
	Textarea: 'Textarea',
	Textfield: 'Textfield',
	'Date Picker': 'Date Picker',
	'Month Picker': 'Month Picker',
	'Time Picker': 'Time Picker',
	Rating: 'Rating',
	'Form Layout': 'Form Layout',
	'Form validation': 'Form validation',
	Tables: 'Tables',
	'Simple Table': 'Simple Table',
	Datatable: 'Datatable',
	'CHARTS AND MAPS': 'CHARTS AND MAPS',
	Charts: 'Charts',
	'Apex Chart': 'Apex Chart',
	Chartjs: 'Chartjs',
	'Leaflet Maps': 'Leaflet Maps',
	Others: 'Others',
	Other: 'Other',
	about: 'About',
	hear: 'Hear',
	'Menu Levels': 'Menu Levels',
	'Menu Level 2.1': 'Menu Level 2.1',
	'Menu Level 2.2': 'Menu Level 2.2',
	'Menu Level 3.1': 'Menu Level 3.1',
	'Menu Level 3.2': 'Menu Level 3.2',
	'Disabled Menu': 'Disabled Menu',
	'Raise Support': 'Raise Support',
	Documentation: 'Documentation',
	'Charts & Maps': 'Charts & Maps',
	Apps: 'Apps',
	'User Interface': 'User Interface',
	'Forms & Tables': 'Forms & Tables',
	applicationText: 'Application Text',
	applicationAdress: 'Application Address',
	applicationAdressPlaceholder: 'Website or E-mail',
	showSalary: 'Show Salary',
	customSalary: 'Custom Salary',
	'Company Name': 'Company Name',
	'Number Vacations': 'Number Vacations',
	'Publication Date': 'Publication Date',
	'Expiration Date': 'Expiration Date',
	apiError: 'An unknown error occurred while communicating with the server',
	rangeSalary: 'Range Salary',
	noHave: 'No have',
	forDisablePerson: 'For People with Disabilities',
	isFeatured: 'Premium',
}
