import { serialize } from '@/@core/libs/serialize/util'
import Http from './http'

// MESSAGES
export const messages = (params = '') => Http.get(`/contact${serialize(params)}`)
export const deleteMessage = (params = '') => Http.delete(`/contact/${params.id}`)
export const markMessageRead = (params = '') => Http.get(`/contact/${params.uuid}`)

// NEWSLETTER
export const newsletters = (params = '') => Http.get(`/newsletters${serialize(params)}`)
export const deleteNewsletter = (params = '') => Http.delete(`/newsletters/${params.id}`)
export const newsletterCreate = (params = '') => Http.post('/newsletters', params)
export const newsletterByID = (params = '') => Http.get(`/newsletters/${params}`)
export const newsletterUpdate = (params = '') => Http.put(`/newsletters/${params.id}`, params)

// REQUIRED SKILLS
export const requiredSkills = (params = '') => Http.get(`/skills/${serialize(params)}`)

// NETWORK SYSTEMS
export const networkSystems = (params = '') => Http.get(`/network-systems${serialize(params)}&sort=id&order=asc`)

// HEALTH CHECK
export const healthCheck = (params = '') =>
	Http.get(`/network-systems${serialize(params)}&filter[status]=1&sort=name&order=asc`)
export const healthCheckMessage = (params = '') => Http.get(`/network-systems-general-status${serialize(params)}`)

// TEAM BIRTHDAYS
export const teamBirthdays = (params = '') =>
	Http.get(`/teams-members-birthdays${serialize(params)}&filter[nexts]=1&per_page=all`)

// LANGUAGES
export const languages = (params = '') => Http.get(`/languages/${serialize(params)}`)

// ALL STATUS GENERAL
export const allStatus = (params = '') => Http.get('/status-general/')

// COMPANIES TYPES CATEGORIES
export const allCompaniesTypes = (params = '') => Http.get('/network-companies-categories/')

// NETWORK COMPANIES
export const allNetworkCompanies = (params = '') => Http.get('/network-companies/')

// ADDRESSES
export const countries = (params = '') => Http.get(`/countries/${serialize(params)}`)
export const states = (country_id, params = '') => Http.get(`/countries/${country_id}/states${serialize(params)}`)
export const regions = (params = '') => Http.get(`/countries-regions/${serialize(params)}`)
export const cities = (city, params = '') => Http.get(`/search-city/${city}${serialize(params)}`)

// OPERATIONAL AREA
export const allOperationalArea = params => Http.get(`/operational-areas${serialize(params)}`)

// WORK METHODOLOGY TYPES
export const allWorkMethodology = params => Http.get(`/work-methodology${serialize(params)}`)

// VISA SPONSORSHIP
export const allVisaSponsorship = params => Http.get(`/visa-sponsorship${serialize(params)}`)

// APPLICATION METHOD
export const allApplicationMethod = params => Http.get(`/application-method${serialize(params)}`)

// INDUSTRY CATEGORIES
export const allIndustryCategories = params => Http.get(`/industry-sector-category${serialize(params)}`)

// EXPERIENCE
export const allExperiences = params => Http.get(`/experiences${serialize(params)}`)

// WORK HOUR
export const allWorkHours = params => Http.get(`/work-hours${serialize(params)}`)

// PAYMENT PERIODS
export const allPaymentPeriods = params => Http.get(`/payment-periods-all${serialize(params)}`)

// CURRENCY
export const allCurrencies = params => Http.get(`/countries-currencies${serialize(params)}`)

// BLOG CATEGORIES
export const allCategories = params => Http.get(`/blog-categories${serialize(params)}`)

// BLOG STATUSES
export const allStatuses = params => Http.get(`/blog/list-status${serialize(params)}`)

// NETWORK COMPANIES
export const allCompanyName = params => Http.get(`/network-companies${serialize(params)}`)
