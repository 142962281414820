import {
	mdiAccountBoxOutline,
	mdiAccountSupervisor,
	mdiCardAccountDetails,
	mdiCash100,
	mdiCogs,
	mdiDomain,
	mdiFactory,
	mdiHandshake,
	mdiHome,
	mdiKeyVariant,
	mdiMap,
	mdiMessage,
	mdiNetwork,
	mdiNewspaperVariantMultipleOutline,
} from '@mdi/js'

// Array of sections

export default [
	{
		icon: mdiHome,
		title: 'Dashboard',
		to: 'network-dashboard',
	},
	{
		title: 'menu.blog',
		icon: mdiNewspaperVariantMultipleOutline,
		children: [
			{
				title: 'Posts',
				to: 'blog-posts-list',
			},
			{
				title: 'categories',
				to: 'blog-categories-list',
			},
			{
				title: 'Tags',
				to: 'blog-tags-list',
			},
		],
	},
	{
		title: 'menu.institutional',
		icon: mdiAccountBoxOutline,
		children: [
			{
				title: 'APIS',
				to: 'apis-list',
			},
			{
				title: 'MG Companies',
				to: 'mg-companies-list',
			},
			{
				title: 'menu.companies.companiesType',
				to: 'institutional-companies-type-list',
			},
		],
	},
	{
		icon: mdiKeyVariant,
		title: 'menu.projectsAndKeys.title',
		children: [
			{
				title: 'menu.projectsAndKeys.list',
				to: 'network-keys-list',
			},
			{
				title: 'menu.projectsAndKeys.projects',
				to: 'network-list-projects',
				roles: ['MGN,admin'],
			},
		],
	},
	{
		icon: mdiFactory,
		title: 'menu.jobs.industrySectors',
		to: 'industry-sector-categories-list',
	},
	{
		icon: mdiCardAccountDetails,
		title: 'menu.careers.title',
		children: [
			{
				title: 'menu.careers.applications',
				to: 'network-careers-applications-list',
			},
			{
				title: 'menu.careers.careers',
				to: 'network-careers-list',
			},
			{
				title: 'menu.careers.skills',
				to: 'network-careers-skills-list',
			},
		],
	},
	{
		title: 'menu.companies.companies',
		icon: mdiDomain,
		children: [
			{
				title: 'menu.companies.companiesList',
				to: 'companies-list',
			},
			{
				title: 'menu.companies.companiesType',
				to: 'companies-type-list',
			},
			{
				title: 'menu.companies.companiesSize',
				to: 'companies-size-list',
			},
			{
				title: 'menu.companies.companiesStage',
				to: 'companies-stage-list',
			},
		],
	},
	{
		icon: mdiHandshake,
		title: 'menu.partners.title',
		children: [
			{
				title: 'menu.partners.list',
				to: 'network-partners-list',
			},
			{
				title: 'menu.partners.categories',
				to: 'network-partners-categories-list',
			},
		],
	},

	{
		title: 'menu.pressMedia.title',
		icon: mdiNewspaperVariantMultipleOutline,
		children: [
			{
				title: 'menu.pressMedia.posts',
				to: 'network-press-posts-list',
			},
			{
				title: 'menu.pressMedia.files',
				to: 'dashboard-analytics',
			},
		],
	},
	{
		icon: mdiCash100,
		title: 'menu.financialSystem.title',
		children: [
			{
				title: 'menu.financialSystem.banks',
				to: 'network-financial-banks-list',
			},
			{
				title: 'menu.financialSystem.currencies',
				to: 'network-financial-currencies-list',
			},
			{
				title: 'menu.financialSystem.cupons',
				to: 'network-financial-cupons-list',
			},
			{
				title: 'menu.financialSystem.expensesCategories',
				to: 'network-financial-expenses-categories-list',
			},
			{
				title: 'menu.financialSystem.invoices',
				to: 'network-financial-invoices-list',
			},
			{
				title: 'menu.financialSystem.paymentFees',
				to: 'network-financial-payment-fees-list',
			},
			{
				title: 'menu.financialSystem.paymentMethods',
				to: 'network-financial-payment-methods-list',
			},
			{
				title: 'menu.financialSystem.paymentPeriods',
				to: 'network-financial-payment-periods-list',
			},
			{
				title: 'menu.financialSystem.paymentRefunds',
				to: 'network-financial-payment-refunds-list',
			},
			{
				title: 'menu.financialSystem.paymentStatus',
				to: 'network-financial-payment-status-list',
			},
			{
				title: 'menu.financialSystem.paymentTaxes',
				to: 'network-financial-payment-taxes-list',
			},
			{
				title: 'menu.financialSystem.paymentTypes',
				to: 'network-financial-payment-types-list',
			},
		],
	},
	{
		icon: mdiMap,
		title: 'menu.languagesAndLocations.title',
		children: [
			{
				title: 'menu.languagesAndLocations.cities',
				to: 'network-cities-list',
			},
			{
				title: 'menu.languagesAndLocations.countries',
				to: 'network-countries-list',
			},
			{
				title: 'menu.languagesAndLocations.languages',
				to: 'network-languages-list',
			},
			{
				title: 'menu.languagesAndLocations.nationalities',
				to: 'network-nationalities-list',
			},
			{
				title: 'menu.languagesAndLocations.regions',
				to: 'network-regions-list',
			},
			{
				title: 'menu.languagesAndLocations.states',
				to: 'network-states-list',
			},
			{
				title: 'menu.languagesAndLocations.timezones',
				to: 'network-timezones-list',
			},
		],
	},

	{
		icon: mdiNetwork,
		title: 'menu.networkSystems.title',
		children: [
			{
				title: 'ACLs',
				children: [
					{
						title: 'menu.networkSystems.ACL.rolesList',
						// to: 'network-acl-roles-list',
						to: 'mg-platforms-roles',
					},
					{
						title: 'menu.networkSystems.ACL.permissionsList',
						// to: 'network-acl-permissions-list',
						to: 'mg-platforms-permissions',
					},
				],
			},
			{
				title: 'menu.networkSystems.documents',
				to: 'dashboard-analytics',
			},
			{
				title: 'menu.networkSystems.documentsTypes',
				to: 'network-documents-types-list',
			},
			{
				title: 'menu.networkSystems.documentsWebsites',
				to: 'network-documents-websites-list',
			},
			{
				title: 'menu.networkSystems.domains',
				to: 'network-domains-list',
			},

			{
				// icon: mdiFrequentlyAskedQuestions,
				title: 'FAQs',
				children: [
					{
						title: 'menu.networkSystems.FAQs',
						// to: 'network-faqs-list',
						to: 'mg-platforms-faqs',
					},
					{
						title: 'menu.networkSystems.FAQsCategories',
						to: 'network-faqs-categories-list',
					},
				],
			},
			{
				title: 'menu.networkSystems.MGPlatforms',
				to: 'network-network-platforms-list',
			},
			{
				title: 'menu.networkSystems.operationalArea',
				to: 'network-operational-area-list',
			},
			{
				title: 'menu.networkSystems.plans',
				children: [
					{
						title: 'menu.networkSystems.benefits',
						to: 'network-benefits-list',
					},
					{
						title: 'menu.networkSystems.plans',
						to: 'network-plans-list',
					},
				],
			},

			{
				title: 'menu.networkSystems.products',
				to: 'network-products-list',
			},

			{
				title: 'menu.networkSystems.productBundles',
				to: 'network-product-bundles-list',
			},

			{
				title: 'menu.networkSystems.roadmap',
				children: [
					{
						title: 'menu.networkSystems.roadmap',
						to: 'network-roadmap-list',
					},
					{
						title: 'menu.networkSystems.roadmapStatus',
						to: 'network-roadmap-status-list',
					},
				],
			},
			{
				title: 'menu.networkSystems.teamMembers',
				to: 'network-members-list',
			},
			{
				title: 'menu.networkSystems.dayTips',
				to: 'network-day-tips-list',
			},
			{
				title: 'Themes',
				children: [
					{
						title: 'menu.themes.appThemes',
						to: 'app-themes-list',
					},
					{
						title: 'menu.themes.projectsVariables',
						to: 'projects-variables-list',
					},
				],
			},
			{
				title: 'menu.networkSystems.workflow',
				to: 'network-workflow-status-list',
			},
		],
	},

	// {
	// 	icon: mdiChartTimelineVariantShimmer,
	// 	title: 'menu.reports.title',
	// 	children: [
	// 		{
	// 			title: 'menu.reports.payments',
	// 			to: 'dashboard-analytics',
	// 		},
	// 		{
	// 			title: 'menu.reports.operational',
	// 			to: 'dashboard-analytics',
	// 		},
	// 	],
	// },
	{
		icon: mdiAccountSupervisor,
		title: 'menu.users.title',
		children: [
			{
				title: 'menu.users.list',
				to: 'users-list',
			},
			{
				title: 'menu.users.genders',
				to: 'network-users-genders-list',
			},
			{
				title: 'menu.users.group',
				to: 'dashboard-analytics',
			},
		],
	},
	{
		icon: mdiCogs,
		title: 'menu.settings',
		children: [
			{
				title: 'menu.socialiteProviders',
				to: 'network-socialite-providers-list',
			},
		],
	},

	{
		icon: mdiMessage,
		title: 'menu.messages',
		to: 'mg-platforms-messages',
	},
	{
		icon: mdiNewspaperVariantMultipleOutline,
		title: 'Newsletter',
		to: 'mg-platforms-newsletters',
	},
]
