import { searchGame } from '@/services/community/wikigamer/games/games'
import {
	allApplicationMethod,
	allCategories,
	allCompaniesTypes,
	allCompanyName,
	allCurrencies,
	allExperiences,
	allIndustryCategories,
	allNetworkCompanies,
	allOperationalArea,
	allPaymentPeriods,
	allStatus,
	allStatuses,
	allVisaSponsorship,
	allWorkHours,
	allWorkMethodology,
	cities,
	countries,
	deleteMessage,
	deleteNewsletter,
	healthCheck,
	healthCheckMessage,
	languages,
	markMessageRead,
	messages,
	networkSystems,
	newsletterByID,
	newsletterCreate,
	newsletters,
	newsletterUpdate,
	regions,
	requiredSkills,
	states,
	teamBirthdays,
} from '@/services/utilities.service'

export default {
	namespaced: true,
	state: {
		// LANGUAGES
		languages: [],
		languagesStatus: 'init',

		// ALL STATUS
		allStatus: [],
		allStatusStatus: 'init',

		// COMPANIES TYPES
		companyTypes: [],
		companyTypesStatus: 'init',

		networkCompanies: [],
		networkCompaniesStatus: 'init',

		// LOCATIONS
		countries: [],
		countriesStatus: 'pending',
		states: [],
		statesStatus: 'pending',
		regions: [],
		regionsStatus: 'pending',
		cities: [],
		citiesStatus: 'pending',

		// OPERATIONAL AREA
		allOperationalArea: [],
		operationalAreaStatus: 'init',

		// NETWORK SYSTEMS
		networkSystems: [],
		networkSystemsStatus: 'init',

		// HEALTH CHECK
		healthCheck: [],
		healthCheckStatus: 'init',

		// HEALTH CHECK
		healthCheckMessage: [],
		healthCheckStatusMessage: 'init',

		// TEAM BIRTHDAYS
		teamBirthdays: [],
		teamBirthdaysStatus: 'init',

		// MESSAGES
		messages: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		messagesStatus: 'init',
		markMessageReadStatus: 'init',
		deleteMessageStatus: 'init',

		// NEWSLETTER
		newsletters: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		newsletter: {},
		newslettersStatus: 'init',
		newslettersSaveStatus: 'init',
		deleteNewsletterStatus: 'init',

		// REQUIRED SKILLS
		requiredSkills: [],
		requiredSkillsStatus: 'init',

		// WORK METHODOLOGY TYPES
		workMethodology: [],
		workMethodologyStatus: 'init',

		// VISA SPONSORSHIP
		visaSponsorship: [],
		visaSponsorshipStatus: 'init',

		// APPLICATION METHOD
		applicationMethod: [],
		applicationMethodStatus: 'init',

		// INDUSTRY CATEGORIES
		industryCategories: [],
		industryCategoriesStatus: 'init',

		// EXPERIENCE
		allExperiences: [],
		allExperiencesStatus: 'init',

		// CURRENCIES
		allCurrencies: [],
		allCurrenciesStatus: 'init',

		// BLOG CATEGORIES
		allCategories: [],
		allCategoriesStatus: 'init',

		// BLOG STATUSES
		allStatuses: [],
		allStatusesStatus: 'init',

		// WORK HOUR
		workHours: [],
		workHoursStatus: 'init',

		// PAYMENT PERIODS
		paymentPeriods: [],
		paymentPeriodsStatus: 'init',

		// GAMES
		games: [],
		gamesStatus: 'init',

		// COMPANY NETWORK
		companyName: [],
		companyNameStatus: 'init',
	},
	getters: {
		// LANGUAGES
		languages(state) {
			return state.languages
		},
		languagesStatus(state) {
			return state.languagesStatus
		},

		// ALL STATUS
		allStatus(state) {
			return state.allStatus
		},
		allStatusStatus(state) {
			return state.allStatusStatus
		},

		// COMPANIES TYPES
		companyTypes(state) {
			return state.companyTypes
		},
		companyTypesStatus(state) {
			return state.companyTypesStatus
		},

		// NETWORK COMPANIES
		networkCompanies(state) {
			return state.networkCompanies
		},
		networkCompaniesStatus(state) {
			return state.networkCompaniesStatus
		},

		// LOCATIONS
		countries(state) {
			return state.countries
		},
		countriesStatus(state) {
			return state.countriesStatus
		},
		regions(state) {
			return state.regions
		},
		regionsStatus(state) {
			return state.regionsStatus
		},
		states(state) {
			return state.states
		},
		statesStatus(state) {
			return state.statesStatus
		},
		cities(state) {
			return state.cities
		},
		citiesStatus(state) {
			return state.citiesStatus
		},

		// OPERATIONAL AREA
		allOperationalArea(state) {
			return state.allOperationalArea
		},
		operationalAreaStatus(state) {
			return state.operationalAreaStatus
		},

		// NETWORK SYSTEMS
		networkSystems(state) {
			return state.networkSystems
		},
		networkSystemsStatus(state) {
			return state.networkSystemsStatus
		},

		// HEALTH CHECK
		healthCheck(state) {
			return state.healthCheck
		},
		healthCheckStatus(state) {
			return state.healthCheckStatus
		},

		// HEALTH CHECK
		healthCheckMessage(state) {
			return state.healthCheckMessage
		},
		healthCheckStatusMessage(state) {
			return state.healthCheckStatusMessage
		},

		// TEAM BIRTHDAYS
		teamBirthdays(state) {
			return state.teamBirthdays
		},
		teamBirthdaysStatus(state) {
			return state.teamBirthdaysStatus
		},

		// REQUIRED SKILLS
		requiredSkills(state) {
			return state.requiredSkills
		},
		requiredSkillsStatus(state) {
			return state.requiredSkillsStatus
		},

		// WORK METHODOLOGY TYPES
		workMethodology(state) {
			return state.workMethodology
		},
		workMethodologyStatus(state) {
			return state.workMethodologyStatus
		},

		// VISA SPONSORSHIP
		visaSponsorship(state) {
			return state.visaSponsorship
		},
		visaSponsorshipStatus(state) {
			return state.visaSponsorshipStatus
		},

		// APPLICATION METHOD
		applicationMethod(state) {
			return state.applicationMethod
		},
		applicationMethodStatus(state) {
			return state.applicationMethodStatus
		},

		// INDUSTRY CATEGORIES
		industryCategories(state) {
			return state.industryCategories
		},
		industryCategoriesStatus(state) {
			return state.industryCategoriesStatus
		},

		// WORK HOUR
		workHours(state) {
			return state.workHours
		},
		workHoursStatus(state) {
			return state.workHoursStatus
		},

		// PAYMENT PERIODS
		paymentPeriods(state) {
			return state.paymentPeriods
		},
		paymentPeriodsStatus(state) {
			return state.paymentPeriodsStatus
		},

		// EXPERIENCE
		allExperiences(state) {
			return state.allExperiences
		},
		allExperiencesStatus(state) {
			return state.allExperiencesStatus
		},

		// CURRENCIES
		allCurrencies(state) {
			return state.allCurrencies
		},
		allCurrenciesStatus(state) {
			return state.allCurrenciesStatus
		},

		// BLOG CATEGORIES
		allCategories(state) {
			return state.allCategories
		},
		allCategoriesStatus(state) {
			return state.allCategoriesStatus
		},

		// BLOG STATUSES
		allStatuses(state) {
			return state.allStatuses
		},
		allStatusesStatus(state) {
			return state.allStatusesStatus
		},

		// MESSAGES
		messages(state) {
			return state.messages
		},
		messagesStatus(state) {
			return state.messagesStatus
		},
		markMessageReadStatus(state) {
			return state.markMessageReadStatus
		},
		deleteMessageStatus(state) {
			return state.deleteMessageStatus
		},

		// NEWSLETTER
		newsletters(state) {
			return state.newsletters
		},
		getNewsletter(state) {
			return state.newsletter
		},
		newslettersStatus(state) {
			return state.newslettersStatus
		},
		getNewslettersSaveStatus(state) {
			return state.newslettersSaveStatus
		},
		deleteNewsletterStatus(state) {
			return state.deleteNewsletterStatus
		},

		// GAMES
		getGames(state) {
			return state.games
		},
		getGamesStatus(state) {
			return state.gamesStatus
		},

		// COMPANY NETWORK
		companyName(state) {
			return state.companyName
		},
		companyNameStatus(state) {
			return state.companyNameStatus
		},
	},
	mutations: {
		// LANGUAGES
		SET_LANGUAGES(state, val) {
			state.languages = val.data
		},
		SET_LANGUAGES_STATUS(state, val) {
			state.languagesStatus = val
		},

		// ALL STATUS
		SET_ALL_STATUS(state, val) {
			state.allStatus = val.data
		},
		SET_ALL_STATUS_STATUS(state, val) {
			state.allStatusStatus = val
		},

		// COMPANIES TYPES
		SET_COMPANIES_TYPES(state, val) {
			state.companyTypes = val.data
		},
		SET_COMPANIES_TYPES_STATUS(state, val) {
			state.companyTypesStatus = val
		},

		SET_NETWORK_COMPANIES(state, val) {
			state.networkCompanies = val
		},
		SET_NETWORK_COMPANIES_STATUS(state, val) {
			state.networkCompaniesStatus = val
		},

		// LOCATIONS
		SET_COUNTRIES(state, val) {
			state.countries = val
		},
		SET_COUNTRIES_STATUS(state, val) {
			state.countriesStatus = val
		},
		SET_REGIONS(state, val) {
			state.regions = val
		},
		SET_REGIONS_STATUS(state, val) {
			state.regionsStatus = val
		},
		SET_STATES(state, val) {
			state.states = val
		},
		SET_STATES_STATUS(state, val) {
			state.statesStatus = val
		},
		SET_CITIES(state, val) {
			state.cities = val
		},
		SET_CITIES_STATUS(state, val) {
			state.citiesStatus = val
		},

		// OPERATIONAL AREA
		SET_ALL_OPERATIONAL_AREA(state, val) {
			state.allOperationalArea = val.data
		},
		SET_OPERATIONAL_STATUS(state, val) {
			state.operationalAreaStatus = val
		},

		// NETWORK SYSTEMS
		SET_NETWORK_SYSTEMS(state, val) {
			state.networkSystems = val
		},
		SET_NETWORK_SYSTEMS_STATUS(state, val) {
			state.networkSystemsStatus = val
		},

		// HEALTH CHECK
		SET_HEALTH_CHECK(state, val) {
			state.healthCheck = val
		},
		SET_HEALTH_CHECK_STATUS(state, val) {
			state.healthCheckStatus = val
		},

		// HEALTH CHECK
		SET_HEALTH_CHECK_MESSAGE(state, val) {
			state.healthCheckMessage = val
		},
		SET_HEALTH_CHECK_STATUS_MESSAGE(state, val) {
			state.healthCheckStatusMessage = val
		},

		// TEAM BIRTHDAYS
		SET_TEAM_BIRTHDAYS(state, val) {
			state.teamBirthdays = val
		},
		SET_TEAM_BIRTHDAYS_STATUS(state, val) {
			state.teamBirthdaysStatus = val
		},

		// REQUIRED SKILLS
		SET_REQUIRED_SKILLS(state, val) {
			state.requiredSkills = val
		},
		SET_REQUIRED_SKILLS_STATUS(state, val) {
			state.requiredSkillsStatus = val
		},

		// WORK METHODOLOGY TYPES
		SET_WORK_METHODOLOGY(state, val) {
			state.workMethodology = val
		},
		SET_WORK_METHODOLOGY_STATUS(state, val) {
			state.workMethodologyStatus = val
		},

		// VISA SPONSORSHIP
		SET_VISA_SPONSORSHIP(state, val) {
			state.visaSponsorship = val
		},
		SET_VISA_SPONSORSHIP_STATUS(state, val) {
			state.visaSponsorshipStatus = val
		},

		// APPLICATION METHOD
		SET_APPLICATION_METHOD(state, val) {
			state.applicationMethod = val
		},
		SET_APPLICATION_METHOD_STATUS(state, val) {
			state.applicationMethodStatus = val
		},

		// INDUSTRY CATEGORIES
		SET_INDUSTRY_CATEGORIES(state, val) {
			state.industryCategories = val
		},
		SET_INDUSTRY_CATEGORIES_STATUS(state, val) {
			state.industryCategoriesStatus = val
		},

		// PAYMENT PERIODS
		SET_PAYMENT_PERIODS(state, val) {
			state.paymentPeriods = val
		},
		SET_PAYMENT_PERIODS_STATUS(state, val) {
			state.paymentPeriodsStatus = val
		},

		// EXPERIENCE
		SET_EXPERIENCES(state, val) {
			state.allExperiences = val
		},
		SET_EXPERIENCES_STATUS(state, val) {
			state.allExperiencesStatus = val
		},

		// CURRENCIES
		SET_CURRENCIES(state, val) {
			state.allCurrencies = val
		},
		SET_CURRENCIES_STATUS(state, val) {
			state.allCurrenciesStatus = val
		},

		// BLOG CATEGORIES
		SET_CATEGORIES(state, val) {
			state.allCategories = val
		},
		SET_CATEGORIES_STATUS(state, val) {
			state.allCategoriesStatus = val
		},

		// BLOG STATUSES
		SET_STATUSES(state, val) {
			state.allStatuses = val
		},
		SET_STATUSES_STATUS(state, val) {
			state.allStatusesStatus = val
		},

		// WORK HOUR
		SET_WORK_HOURS(state, val) {
			state.workHours = val
		},
		SET_WORK_HOURS_STATUS(state, val) {
			state.workHoursStatus = val
		},

		// MESSAGES
		SET_MESSAGES(state, val) {
			state.messages = val
		},
		// MESSAGES
		MARK_MESSAGE_AS_READ(state, val) {
			state.markMessageReadStatus = val
		},
		SET_MESSAGES_STATUS(state, val) {
			state.messagesStatus = val
		},
		SET_DELETE_MESSAGE_STATUS(state, val) {
			state.deleteMessageStatus = val
		},

		// NEWSLETTERS
		SET_NEWSLETTERS(state, val) {
			state.newsletters = val
		},
		SET_NEWSLETTER(state, value) {
			state.newsletter = value
		},
		SET_NEWSLETTERS_STATUS(state, val) {
			state.newslettersStatus = val
		},
		SET_DELETE_NEWSLETTER_STATUS(state, val) {
			state.deleteMessageStatus = val
		},
		NEWSLETTER_SAVE_STATUS(state, value) {
			state.newslettersSaveStatus = value
		},

		// GAMES
		SET_GAMES(state, games) {
			state.games = games
		},
		SET_GAMES_STATUS(state, status) {
			state.gamesStatus = status
		},

		// COMPANY NETWORK
		SET_COMPANY_NAME(state, val) {
			state.companyName = val
		},
		SET_COMPANY_NAME_STATUS(state, val) {
			state.companyNameStatus = val
		},
	},
	actions: {
		// LANGUAGES
		async fetchLanguages({ commit }, params) {
			commit('SET_LANGUAGES_STATUS', 'pending')
			try {
				const { data } = await languages(params)
				commit('SET_LANGUAGES', data)
				commit('SET_LANGUAGES_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_LANGUAGES_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		// ALL STATUS
		async fetchAllStatus({ commit }, params) {
			commit('SET_ALL_STATUS_STATUS', 'pending')
			try {
				const { data } = await allStatus(params)
				commit('SET_ALL_STATUS', data)
				commit('SET_ALL_STATUS_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_ALL_STATUS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		// COMPANIES STATUS
		async fetchCompaniesTypes({ commit }, params) {
			commit('SET_COMPANIES_TYPES_STATUS', 'pending')
			try {
				const { data } = await allCompaniesTypes(params)
				commit('SET_COMPANIES_TYPES', data)
				commit('SET_COMPANIES_TYPES_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_COMPANIES_TYPES_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		async fetchNetworkCompanies({ commit }) {
			commit('SET_NETWORK_COMPANIES_STATUS', 'pending')
			try {
				const data = await allNetworkCompanies()
				commit('SET_NETWORK_COMPANIES', data.data)
				commit('SET_NETWORK_COMPANIES_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_NETWORK_COMPANIES_STATUS', 'error')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		// LOCATIONS ACTIONS
		async fetchCountries({ state, commit }, params) {
			commit('SET_COUNTRIES_STATUS', 'pending')
			try {
				const { data } = await countries(params)
				commit('SET_COUNTRIES', data.data)
				commit('SET_COUNTRIES_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_COUNTRIES_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},

		async fetchRegions({ state, commit }, params) {
			commit('SET_REGIONS_STATUS', 'pending')
			try {
				const { data } = await regions(params)
				commit('SET_REGIONS', data.data)
				commit('SET_REGIONS_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_REGIONS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},

		async fetchStates({ state, commit }, { country_id, ...params }) {
			commit('SET_STATES_STATUS', 'pending')
			try {
				const { data } = await states(country_id, params)
				commit('SET_STATES', data.data)
				commit('SET_STATES_STATUS', 'init')

				return data
			} catch (error) {
				commit('SET_STATES_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response?.data?.message || 'Erro ao carregar estados',
					type: 'danger',
				})

				return error
			}
		},

		async fetchCities({ state, commit }, { city = '', ...params } = {}) {
			commit('SET_CITIES_STATUS', 'pending')
			try {
				const { data } = await cities(city, params)
				commit('SET_CITIES', data.data)
				commit('SET_CITIES_STATUS', 'init')

				return data
			} catch (error) {
				commit('SET_CITIES_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				return error
			}
		},

		// OPERATIONAL AREA
		async fetchAllOperationalAreas({ commit }, params) {
			commit('SET_OPERATIONAL_STATUS', 'pending')
			try {
				const { data } = await allOperationalArea(params)
				commit('SET_ALL_OPERATIONAL_AREA', data)
				commit('SET_OPERATIONAL_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_OPERATIONAL_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		// NETWORK SYSTEMS
		async fetchNetworkSystems({ commit }, params) {
			commit('SET_NETWORK_SYSTEMS_STATUS', 'pending')
			try {
				const { data } = await networkSystems(params)
				commit('SET_NETWORK_SYSTEMS', data.data)
				commit('SET_NETWORK_SYSTEMS_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_NETWORK_SYSTEMS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		// NETWORK SYSTEMS
		async fetchHealthCheck({ commit }, params) {
			commit('SET_HEALTH_CHECK_STATUS', 'pending')
			try {
				const { data } = await healthCheck(params)
				commit('SET_HEALTH_CHECK', data.data)
				commit('SET_HEALTH_CHECK_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_HEALTH_CHECK_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		// NETWORK SYSTEMS STATUS
		async fetchHealthCheckMessage({ commit }, params) {
			commit('SET_HEALTH_CHECK_STATUS_MESSAGE', 'pending')
			try {
				const { data } = await healthCheckMessage(params)
				commit('SET_HEALTH_CHECK_MESSAGE', data.data)
				commit('SET_HEALTH_CHECK_STATUS_MESSAGE', 'init')
				return data
			} catch (error) {
				commit('SET_HEALTH_CHECK_STATUS_MESSAGE', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		// TEAM BIRTHDAYS
		async fetchTeamBirthdays({ commit }, params) {
			commit('SET_TEAM_BIRTHDAYS_STATUS', 'pending')
			try {
				const { data } = await teamBirthdays(params)
				commit('SET_TEAM_BIRTHDAYS', data.data)
				commit('SET_TEAM_BIRTHDAYS_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_TEAM_BIRTHDAYS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		// REQUIRED SKILLS
		async fetchRequiredSkills({ commit }, params) {
			commit('SET_REQUIRED_SKILLS_STATUS', 'pending')
			try {
				const { data } = await requiredSkills(params)
				commit('SET_REQUIRED_SKILLS', data.data)
				commit('SET_REQUIRED_SKILLS_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_REQUIRED_SKILLS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		// WORK METHODOLOGY TYPES
		async fetchWorkMethodology({ commit }, params) {
			commit('SET_WORK_METHODOLOGY_STATUS', 'pending')
			try {
				const { data } = await allWorkMethodology(params)
				commit('SET_WORK_METHODOLOGY', data.data)
				commit('SET_WORK_METHODOLOGY_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_WORK_METHODOLOGY_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		// VISA SPONSORSHIP
		async fetchVisaSponsorship({ commit }, params) {
			commit('SET_VISA_SPONSORSHIP_STATUS', 'pending')
			try {
				const { data } = await allVisaSponsorship(params)
				commit('SET_VISA_SPONSORSHIP', data.data)
				commit('SET_VISA_SPONSORSHIP_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_VISA_SPONSORSHIP_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		// APPLICATION METHOD
		async fetchApplicationMethod({ commit }, params) {
			commit('SET_APPLICATION_METHOD_STATUS', 'pending')
			try {
				const { data } = await allApplicationMethod(params)
				commit('SET_APPLICATION_METHOD', data.data)
				commit('SET_APPLICATION_METHOD_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_APPLICATION_METHOD_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		// INDUSTRY CATEGORIES
		async fetchIndustryCategories({ commit }, params) {
			commit('SET_INDUSTRY_CATEGORIES_STATUS', 'pending')
			try {
				const { data } = await allIndustryCategories(params)
				commit('SET_INDUSTRY_CATEGORIES', data.data)
				commit('SET_INDUSTRY_CATEGORIES_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_INDUSTRY_CATEGORIES_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		// PAYMENT PERIODS
		async fetchPaymentPeriods({ commit }, params) {
			commit('SET_PAYMENT_PERIODS_STATUS', 'pending')
			try {
				const { data } = await allPaymentPeriods(params)
				commit('SET_PAYMENT_PERIODS', data.data)
				commit('SET_PAYMENT_PERIODS_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_PAYMENT_PERIODS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		// EXPERIENCE
		async fetchExperiences({ commit }, params) {
			commit('SET_EXPERIENCES_STATUS', 'pending')
			try {
				const { data } = await allExperiences(params)
				commit('SET_EXPERIENCES', data.data)
				commit('SET_EXPERIENCES_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_EXPERIENCES_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		// CURRENCIES
		async fetchCurrencies({ commit }, params) {
			commit('SET_CURRENCIES_STATUS', 'pending')
			try {
				const { data } = await allCurrencies(params)
				commit('SET_CURRENCIES', data.data)
				commit('SET_CURRENCIES_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_CURRENCIES_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		// BLOG CATEGORIES
		async fetchCategories({ commit }, params) {
			commit('SET_CATEGORIES_STATUS', 'pending')
			try {
				const { data } = await allCategories(params)
				commit('SET_CATEGORIES', data.data)
				commit('SET_CATEGORIES_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_CATEGORIES_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		// BLOG STATUSES
		async fetchStatuses({ commit }, params) {
			commit('SET_STATUSES_STATUS', 'pending')
			try {
				const { data } = await allStatuses(params)
				commit('SET_STATUSES', data.data)
				commit('SET_STATUSES_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_STATUSES_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		// WORK HOUR
		async fetchWorkHours({ commit }, params) {
			commit('SET_WORK_HOURS_STATUS', 'pending')
			try {
				const { data } = await allWorkHours(params)
				commit('SET_WORK_HOURS', data.data)
				commit('SET_WORK_HOURS_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_WORK_HOURS_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		// MESSAGES
		async fetchMessages({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_MESSAGES_STATUS', 'pending')
			commit('SET_MESSAGES', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await messages(params)
				console.log(data)
				commit('SET_MESSAGES_STATUS', 'init')
				commit('SET_MESSAGES', data)
				return data
			} catch (error) {
				commit('SET_MESSAGES_STATUS', 'init')
				throw error
			}
		},

		async markMessageAsRead({ commit }, params) {
			commit('MARK_MESSAGE_AS_READ', 'pending')
			try {
				const { data } = await markMessageRead(params)
				commit('MARK_MESSAGE_AS_READ', 'init')
				return data
			} catch (error) {
				commit('MARK_MESSAGE_AS_READ', 'init')
				console.error('Erro ao marcar mensagem como lida:', error)
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		async deleteMessageByID({ commit }, params) {
			commit('SET_DELETE_MESSAGE_STATUS', 'pending')
			try {
				const { data } = await deleteMessage(params)
				commit('SET_DELETE_MESSAGE_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_DELETE_MESSAGE_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		// NEWSLETTERS

		async fetchNewsletters({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('SET_NEWSLETTERS_STATUS', 'pending')
			commit('SET_NEWSLETTERS', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await newsletters(params)
				console.log(data)
				commit('SET_NEWSLETTERS_STATUS', 'init')
				commit('SET_NEWSLETTERS', data)
				return data
			} catch (error) {
				commit('SET_NEWSLETTERS_STATUS', 'init')
				throw error
			}
		},
		async fetchNewsletterByID({ commit }, params) {
			commit('NEWSLETTERS_STATUS', 'pending')
			try {
				const { data } = await newsletterByID(params)
				commit('NEWSLETTERS_STATUS', 'init')
				commit('SET_NEWSLETTER', data.data)
				return data
			} catch (error) {
				commit('NEWSLETTERS_STATUS', 'init')

				return error
			}
		},
		async saveNewNewsletter({ commit }, params) {
			commit('NEWSLETTER_SAVE_STATUS', 'pending')
			try {
				const { data } = await newsletterCreate(params)
				commit('NEWSLETTER_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('NEWSLETTER_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateNewsletter({ commit }, params) {
			commit('NEWSLETTER_SAVE_STATUS', 'pending')
			try {
				const { data } = await newsletterUpdate(params)
				commit('NEWSLETTER_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('NEWSLETTER_SAVE_STATUS', 'init')
				throw error
			}
		},

		async deleteNewsletterByID({ commit }, params) {
			commit('SET_DELETE_NEWSLETTER_STATUS', 'pending')
			try {
				const { data } = await deleteNewsletter(params)
				commit('SET_DELETE_NEWSLETTER_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_DELETE_NEWSLETTER_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},

		// GAMES
		async fetchGames({ commit }, params) {
			commit('SET_GAMES_STATUS', 'pending')

			try {
				const { data } = await searchGame(params)
				commit('SET_GAMES', data.data)
			} catch (err) {
				throw err
			} finally {
				commit('SET_GAMES_STATUS', 'init')
			}
		},

		// COMPANY NETWORK
		async fetchCompanyName({ commit }, params) {
			commit('SET_COMPANY_NAME_STATUS', 'pending')
			try {
				const { data } = await allCompanyName(params)
				commit('SET_COMPANY_NAME', data.data)
				commit('SET_COMPANY_NAME_STATUS', 'init')
				return data
			} catch (error) {
				commit('SET_COMPANY_NAME_STATUS', 'init')
				this._vm.$root.$notify({
					message: error.response.data.message,
					type: 'danger',
				})
				throw error
			}
		},
	},
}
